// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section_title_container {
    margin-top: -150px;
  }
  
  .section_title h2 {
    font-size: 25px;
    color: #ab7f21;
  }
  
  .services_row {
    display: flex;
    flex-wrap: wrap;
    margin-top: -50px;
  }
  
  .service_col {
    cursor: pointer;
    flex: 1 1 30%; /* Adjust to fit 3 items per row */
    margin: 10px;
  }
  
  .service {
    background-color: #ab7f21; /* Example background color */
    color: white;
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s;
    margin-bottom: 10px;
  }

  .service_title{
    color: inherit;
  }
  
  .service:hover {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .icon_container_new {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .icon {
    margin-bottom: 10px;
  }
  
  .service_title {
    font-size: 15px;
  }`, "",{"version":3,"sources":["webpack://./src/Components/ServicesHome/ServiceHome.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,aAAa,EAAE,kCAAkC;IACjD,YAAY;EACd;;EAEA;IACE,yBAAyB,EAAE,6BAA6B;IACxD,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,6CAA6C;IAC7C,mBAAmB;EACrB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".section_title_container {\r\n    margin-top: -150px;\r\n  }\r\n  \r\n  .section_title h2 {\r\n    font-size: 25px;\r\n    color: #ab7f21;\r\n  }\r\n  \r\n  .services_row {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    margin-top: -50px;\r\n  }\r\n  \r\n  .service_col {\r\n    cursor: pointer;\r\n    flex: 1 1 30%; /* Adjust to fit 3 items per row */\r\n    margin: 10px;\r\n  }\r\n  \r\n  .service {\r\n    background-color: #ab7f21; /* Example background color */\r\n    color: white;\r\n    text-align: center;\r\n    padding: 20px;\r\n    border-radius: 8px;\r\n    transition: background-color 0.3s, color 0.3s;\r\n    margin-bottom: 10px;\r\n  }\r\n\r\n  .service_title{\r\n    color: inherit;\r\n  }\r\n  \r\n  .service:hover {\r\n    background-color: #f0f0f0;\r\n    color: #333;\r\n  }\r\n  \r\n  .icon_container_new {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n  }\r\n  \r\n  .icon {\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .service_title {\r\n    font-size: 15px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
