// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */

.super_container {
  position: relative;
}

.sticky-button {
  position: fixed;
  bottom: 20px;
  right: 0px;
  width: 60px;
  height: 60px;
  background-color: #AB7F21;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 1000;
}

.sticky-button.second {
  bottom: 90px;
  background-color: #AB7F21;
}

.sticky-button.third {
  bottom: 160px; /* Adjust distance from bottom */
  background-color: #AB7F21; /* Different background color */
}

.sticky-button a {
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.sticky-button:hover {
  background-color: #D3AC52;
  transform: scale(1.1);
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,YAAY;;AAEZ;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,UAAU;EACV,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,2CAA2C;EAC3C,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,aAAa,EAAE,gCAAgC;EAC/C,yBAAyB,EAAE,+BAA+B;AAC5D;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB","sourcesContent":["/* App.css */\n\n.super_container {\n  position: relative;\n}\n\n.sticky-button {\n  position: fixed;\n  bottom: 20px;\n  right: 0px;\n  width: 60px;\n  height: 60px;\n  background-color: #AB7F21;\n  color: white;\n  border-radius: 5px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n  transition: all 0.3s ease;\n  z-index: 1000;\n}\n\n.sticky-button.second {\n  bottom: 90px;\n  background-color: #AB7F21;\n}\n\n.sticky-button.third {\n  bottom: 160px; /* Adjust distance from bottom */\n  background-color: #AB7F21; /* Different background color */\n}\n\n.sticky-button a {\n  text-decoration: none;\n  color: white;\n  font-size: 14px;\n  font-weight: bold;\n  display: flex;\n  align-items: center;\n}\n\n.sticky-button:hover {\n  background-color: #D3AC52;\n  transform: scale(1.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
