import { FaSpa } from "react-icons/fa";
import { FaPrescriptionBottleAlt } from "react-icons/fa";
import { FaRegSun } from "react-icons/fa";
import { FaWeight } from "react-icons/fa";
import { FaTooth } from "react-icons/fa";
import { FaBriefcaseMedical } from "react-icons/fa";
import { FaSyringe } from "react-icons/fa";
import { GiStomach } from "react-icons/gi";
import { GiScalpel } from "react-icons/gi";
import { MdFace2 } from "react-icons/md";
import { FaTint } from "react-icons/fa";
import { FaUtensils } from "react-icons/fa";
import { GiNoseSide } from "react-icons/gi";
import { IoBody } from "react-icons/io5";
import { GiLaserburn } from "react-icons/gi";
import { GiHairStrands } from "react-icons/gi";
import { GiMedicalDrip } from "react-icons/gi";
import { FaAssistiveListeningSystems } from "react-icons/fa";
import { Link } from 'react-router-dom';

function SpecialitiesPage({ changeService }) {

    const handleClick = (s) => {
        const data = "Hello from Child!";
        changeService(s);
    };


    return (
        <div className="services">
            <div className="container">
                <div className="row" style={{marginTop: "-75px"}}>
                    <div className="col text-center">
                        <div className="section_title_container">
                            <div className="section_title">
                                <h2>Our Services</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row services_row justify-content-center" style={{ marginTop: "100px" }}>

                    {/* Service 1 */}
                    <div className="col-xl-4" >
                        <Link style={{ color: "black" }} to={`/treatment/liposuction`}>
                            <div className="service" style={{ height: "135px" }}>
                                <div className="icon_container_new" >
                                    <div className="icon"><GiScalpel size={40} /></div>
                                </div>
                                <div className="service_title">PLASTIC SURGERY</div>
                            </div>
                        </Link>
                    </div>
                    {/* <div className="col-xl-4" >
                        <Link style={{ color: "black" }} to={`/treatment/morpheus8`}>
                            <div className="service" style={{ height: "135px" }}>
                                <div className="icon_container_new">
                                    <div className="icon"><GiNoseSide size={40} /></div>
                                </div>
                                <div className="service_title">AESTHETIC</div>
                            </div>
                        </Link>
                    </div> */}
                    <div className="col-xl-4">
                        <Link style={{ color: "black" }} to={`/treatment/gynecomastia`}>
                            <div className="service" style={{ height: "135px" }}>
                                <div className="icon_container_new">
                                    <div className="icon"><IoBody size={40} /></div>
                                </div>
                                <div className="service_title">GYNECOMASTIA</div>
                            </div>
                        </Link>
                    </div>
                    {/* <div className="col-xl-4">
                        <Link style={{ color: "black" }} to={`/treatment/gastric-balloon-and-smart-capsule`}>
                            <div className="service" style={{ height: "135px" }}>
                                <div className="icon_container_new">
                                    <div className="icon"><GiScalpel size={40} /></div>
                                </div>
                                <div className="service_title">GASTRIC SURGERY</div>
                            </div>
                        </Link>
                    </div> */}
                    <div className="col-xl-4">
                        <Link style={{ color: "black" }} to={`/treatment/gastric-balloon-and-smart-capsule`}>

                            <div className="service" style={{ height: "135px" }}>
                                <div className="icon_container_new">
                                    <div className="icon"><GiStomach size={40} /></div>
                                </div>
                                <div className="service_title">BALLOON</div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-xl-4">
                        <Link style={{ color: "black" }} to={`/treatment/health-checkup`}>
                            <div className="service" style={{ height: "135px" }}>
                                <div className="icon_container_new">
                                    <div className="icon"><FaWeight size={40} /></div>
                                </div>
                                <div className="service_title">WELLNESS</div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-xl-4">
                        <Link style={{ color: "black" }} to={`/treatment/morpheus8`}>
                            <div className="service" style={{ height: "135px" }}>
                                <div className="icon_container_new">
                                    <div className="icon"><FaSyringe size={40} /></div>
                                </div>
                                <div className="service_title">AESTHETICS</div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-xl-4">
                        <Link style={{ color: "black" }} to={`/treatment/hair-treatment`}>
                            <div className="service" style={{ height: "135px" }}>
                                <div className="icon_container_new">
                                    <div className="icon"><FaAssistiveListeningSystems size={40} /></div>
                                </div>
                                <div className="service_title">HAIR TREATMENT</div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-xl-4">
                        <Link style={{ color: "black" }} to={`/treatment/laser-hair-removal`}>
                            <div className="service" style={{ height: "135px" }}>
                                <div className="icon_container_new">
                                    <div className="icon"><GiLaserburn size={40} /></div>
                                </div>
                                <div className="service_title">LASER TREATMENT</div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-xl-4">
                        <Link style={{ color: "black" }} to={`/treatment/hydrafacial`}>
                            <div className="service" style={{ height: "135px" }}>
                                <div className="icon_container_new">
                                    <div className="icon"><MdFace2 size={40} /></div>
                                </div>
                                <div className="service_title">FACIAL TREATMENT</div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-xl-4">
                        <Link style={{ color: "black" }} to={`/treatment/immune-booster-iv-drip`}>
                            <div className="service" style={{ height: "135px" }}>
                                <div className="icon_container_new">
                                    <div className="icon"><GiMedicalDrip size={40} /></div>
                                </div>
                                <div className="service_title">IV DRIPS</div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-xl-4">
                        <Link style={{ color: "black" }} to={`/treatment/invisalign`}>
                            <div className="service" style={{ height: "135px" }}>
                                <div className="icon_container_new">
                                    <div className="icon"><FaTooth size={40} /></div>
                                </div>
                                <div className="service_title">DENTISTRY</div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-xl-4">
                        <Link style={{ color: "black" }} to={`/treatment/rhinoplasty`}>
                            <div className="service" style={{ height: "135px" }}>
                                <div className="icon_container_new">
                                    <div className="icon"><FaTooth size={40} /></div>
                                </div>
                                <div className="service_title">Rhinoplasty</div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default SpecialitiesPage