import { FaSpa } from "react-icons/fa";
import { FaPrescriptionBottleAlt } from "react-icons/fa";
import { FaRegSun } from "react-icons/fa";
import { FaWeight } from "react-icons/fa";
import { FaTooth } from "react-icons/fa";
import { FaBriefcaseMedical } from "react-icons/fa";
import { FaSyringe } from "react-icons/fa";
import { GiStomach } from "react-icons/gi";
import { GiScalpel } from "react-icons/gi";
import { MdFace2 } from "react-icons/md";
import { FaTint } from "react-icons/fa";
import { FaUtensils } from "react-icons/fa";
import { GiNoseSide } from "react-icons/gi";
import { IoBody } from "react-icons/io5";
import { GiLaserburn } from "react-icons/gi";
import { GiHairStrands } from "react-icons/gi";
import { GiMedicalDrip } from "react-icons/gi";
import { FaAssistiveListeningSystems } from "react-icons/fa";

import "./ServiceHome.css"


function ServicesHome({ changeService }) {

    const featuresData = [


        {
            imgSrc: 'images/services/Endocrinology.jpg',
            alt: 'Dentistry',
            icon: 'FaFlask',
            title: 'Endocrinology',
            desc: "AACSH integrates medical knowledge and extensive care to treat diabetes and other endocrine-related disorders, having one of the best diabetes specialists in Dubai At AACSH our Endocrinologists and Diabetes specialists use advanced technology and innovative equipment, which add to the long-term commitment to patient care. The team renders comprehensive care and non-invasive diagnostic services to all the patients. The Department offers expert consultation, counseling, diagnosing the various problems early, and treating them with great skill and experience."
        },
        // {
        //   imgSrc: 'images/services/ENT.jpg',
        //   alt: 'Dentistry',
        //   icon: 'FaHeadSideCough',
        //   title: 'ENT',
        //   desc: "Ear, Nose, and Throat (ENT), also known as Otolaryngology, is a medical specialty that focuses on the diagnosis, treatment, and management of disorders and conditions related to the ears, nose, throat, and related structures of the head and neck. Otolaryngologists, or ENT specialists, are medical doctors who specialize in this field and provide comprehensive care for a wide range of issues affecting these areas."
        // },
        {
            imgSrc: 'images/services/dermatology.jpg',
            alt: 'Dentistry',
            icon: 'FaRegSun',
            title: 'Dermatology & Antiaging',
            desc: "Dermatology is a medical specialty that focuses on the study, diagnosis, treatment, and prevention of conditions and diseases related to the skin, hair, nails, and mucous membranes. Dermatologists are medical doctors who specialize in dermatology and provide comprehensive care for a wide range of skin-related issues. The skin is the body's largest organ and plays a vital role in protecting the body from external factors, regulating temperature, and serving as a barrier to infection."
        },

        {
            imgSrc: 'images/services/CosmeticSurgery.jpg',
            alt: 'Dentistry',
            icon: 'FaSyringe',
            title: 'Plastic & Cosmetic Surgery',
            desc: "At the American Academy of Cosmetic Surgery Hospital we offer a range of cutting-edge cosmetic surgery procedures designed to enhance your natural beauty and boost your self-confidence. Our team of highly skilled and experienced surgeons is dedicated to providing you with personalized care and achieving your aesthetic goals."
        },
        {
            imgSrc: 'images/services/Bariatric.jpg',
            alt: 'Dentistry',
            icon: 'FaWeight',
            title: 'General & Bariatric Surgery',
            desc: "Obesity is a complex medical condition characterized by excessive body weight due to the accumulation of excess fat. It can lead to various health complications such as diabetes, cardiovascular disease, joint issues, and more. Bariatric services aim to help individuals manage and overcome obesity through a combination of lifestyle changes, dietary modifications, and, in some cases, surgical procedures.AACSH provides ethical, evidence-based obesity management programs according to recognized international standards of patient care.The multi-disciplinary approach program offers the following services:",
            listService: [
                "Medical & Metabolic Assessment",
                "Pharmacotherapy",
                "Nutrition Plans",
                "Orthopedic Examination & Investigation",
                "Physiotherapy & Physical Performance Assessment",
                "Gastric Sleeve",
                "Gastric Balloon",
                "One Anastomosis Gastric Bypass",
                "Classic (Roux en Y) Gastric Bypass",
                "Duodenal Switch",
                "Gastric Plication",
                "Endoscopic Sleeve Gastroplasty",
                "Monitoring & Follow-up",
                "Post Weightloss Plastic Surgery"
            ]
        },
        {
            imgSrc: 'images/services/gynecology.jpg',
            alt: 'Dentistry',
            icon: 'FaVenus',
            title: 'Cosmetic Gynecology',
            desc: "Every woman deserves to feel confident and comfortable in her own body.We offer a comprehensive range of surgical and non-surgical treatments specifically designed to address the unique needs and concerns of women seeking cosmetic gynecology procedures. Our team of highly skilled and experienced cosmetic gynecologists are dedicated to providing the highest standard of care in a compassionate and supportive environment."
        },

        {
            imgSrc: 'images/services/Dietetics.png',
            alt: 'Dietetics',
            icon: 'FaUtensils',
            title: 'Dietetics',
            desc: "Dermatology is a medical specialty that focuses on the study, diagnosis, treatment, and prevention of conditions and diseases related to the skin, hair, nails, and mucous membranes. Dermatologists are medical doctors who specialize in dermatology and provide comprehensive care for a wide range of skin-related issues. The skin is the body's largest organ and plays a vital role in protecting the body from external factors, regulating temperature, and serving as a barrier to infection."
        },
        // {
        //   imgSrc: 'images/services/orthopedics.jpg',
        //   alt: 'Dentistry',
        //   icon: 'FaBone',
        //   title: 'Orthopedics Surgery',
        //   desc: "Orthopedics, also known as orthopaedics, is a medical specialty that focuses on the diagnosis, treatment, and management of conditions and injuries related to the musculoskeletal system. The musculoskeletal system includes the bones, joints, muscles, ligaments, tendons, and other connective tissues that provide the body's structural support and allow for movement. Orthopedic doctors, also known as orthopedic surgeons, are medical professionals who specialize in this field and provide care for a wide range of orthopedic issues."
        // },

        // {
        //   imgSrc: 'images/services/obstetrics_gynaecology.jpg',
        //   alt: 'Dentistry',
        //   icon: 'FaFemale',
        //   title: 'Obstetrics & Gynecology',
        //   desc: "Our expert Gynecologists and Obstetricians at the AACSH provide comprehensive care for women’s health, from adolescence through early menopause and beyond. Our broad spectrum of obstetrics and gynecology services includes infertility, pregnancy, reproductive endocrinology, gynecologic oncology, and urogynecology. Our compassionate physicians take the time to address your individual needs as a woman, including diagnosing and treating reproductive problems and managing both normal pregnancies and high-risk pregnancies. We provide comprehensive, seamless, and integrated care and prenatal and postnatal care, and gynecological exams. "
        // },

        // {
        //   imgSrc: 'images/services/physiotherapy.jpg',
        //   alt: 'Dentistry',
        //   icon: 'FaRunning',
        //   title: 'Physiotherapy',
        //   desc: "At AACSH we aim to provide adequate help and rehabilitation to all its patients.The qualified physiotherapists are enriched with experience in diverse conditions like Musculoskeletal, Neurology,Cardio-Respiratory and other areas."
        // },
        // {
        //   imgSrc: 'images/services/Psychology.jpg',
        //   alt: 'Dentistry',
        //   icon: 'FaYinYang',
        //   title: 'Psychology',
        //   desc: "At AACSH We offer psychological assessments and interventions for children, adolescents, adults, couples and families. After your assessment, our experts will be able to make recommendations on next steps and agree a plan collaboratively with you.Recommendations may include psychological therapy,interventions to support your specific needs or capabilities,referrals to different specialists for further assessments or support, and/or lifestyle changes."
        // },


        // {
        //   imgSrc: 'images/services/opthalmology.jpg',
        //   alt: 'opthalmology',
        //   icon: 'FaRegEye',
        //   title: 'Opthalmology',
        //   desc: "The Ophthalmology department is a modern sophisticated facility, with a mission to help patients with acute eye problems. To fulfill this mission ophthalmology department fills up with newest diagnostic and surgical equipment, contemporary diagnostic equipment including computerized testing for eye refraction, eye pressure, visual field, corneal topography, computer laser tomography of an eye are available."
        // },


        // Add more features as needed
    ];

    const handleClick = (s) => {
        const data = "Hello from Child!";
        // changeService(s);
    };

    return (
        <div className="services">
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <div className="section_title_container">
                            <div className="section_title">
                                <h2>Our Services</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row services_row justify-content-center" >

                    {/* Service 1 */}
                    <div className="col-xl-4" onClick={() => handleClick("PLASTIC SURGERY")} >
                        <div className="service" style={{height: "135px"}}>
                            <div className="icon_container_new" >
                                <div className="icon"><GiScalpel size={40} /></div>
                            </div>
                            <div className="service_title">PLASTIC SURGERY</div>
                        </div>
                    </div>
                    <div className="col-xl-4" onClick={() => handleClick("DENTISTRY")}>
                        <div className="service" style={{height: "135px"}}>
                            <div className="icon_container_new">
                                <div className="icon"><GiNoseSide   size={40} /></div>
                            </div>
                            <div className="service_title">RHINOPLASTY</div>
                        </div>
                    </div>
                    <div className="col-xl-4" onClick={() => handleClick("DENTISTRY")}>
                        <div className="service" style={{height: "135px"}}>
                            <div className="icon_container_new">
                                <div className="icon"><IoBody  size={40} /></div>
                            </div>
                            <div className="service_title">GYNECOMASTIA</div>
                        </div>
                    </div>
                    <div className="col-xl-4" onClick={() => handleClick("DENTISTRY")}>
                        <div className="service" style={{height: "135px"}}>
                            <div className="icon_container_new">
                                <div className="icon"><GiScalpel  size={40} /></div>
                            </div>
                            <div className="service_title">GASTRIC SURGERY</div>
                        </div>
                    </div>
                    <div className="col-xl-4" onClick={() => handleClick("DENTISTRY")}>
                        <div className="service" style={{height: "135px"}}>
                            <div className="icon_container_new">
                                <div className="icon"><GiStomach size={40} /></div>
                            </div>
                            <div className="service_title">BALLOON</div>
                        </div>
                    </div>
                    <div className="col-xl-4" onClick={() => handleClick("DENTISTRY")}>
                        <div className="service" style={{height: "135px"}}>
                            <div className="icon_container_new">
                                <div className="icon"><FaWeight  size={40} /></div>
                            </div>
                            <div className="service_title">SLIMMING</div>
                        </div>
                    </div>
                    <div className="col-xl-4" onClick={() => handleClick("PLASTIC SURGERY")} >
                        <div className="service" style={{height: "135px"}}>
                            <div className="icon_container_new" >
                                <div className="icon"><FaSyringe size={40} /></div>
                            </div>
                            <div className="service_title" style={{fontSize: "14px"}}>DERMATOLOGY AND ANTI AGING</div>
                        </div>
                    </div>

                    

                    {/* Service 3 */}
                    <div className="col-xl-4" onClick={() => handleClick("HAIR LOSS")}>
                        <div className="service" style={{height: "135px"}}>
                            <div className="icon_container_new">
                                <div className="icon"><FaAssistiveListeningSystems size={40} /></div>
                            </div>
                            <div className="service_title">HAIR TREATMENT</div>
                        </div>
                    </div>
                    <div className="col-xl-4" onClick={() => handleClick("DENTISTRY")}>
                        <div className="service" style={{height: "135px"}}>
                            <div className="icon_container_new">
                                <div className="icon"><GiLaserburn size={40} /></div>
                            </div>
                            <div className="service_title">LASER</div>
                        </div>
                    </div>
                    {/* Service 4 */}
                    {/* <div className="col-xl-4 service_col" onClick={() => handleClick("MEDICAL SPA")}>
                        <div className="service" style={{height: "135px"}}>
                            <div className="icon_container_new">
                                <div className="icon"><FaSpa size={40} /></div>
                            </div>
                            <div className="service_title">MEDICAL SPA</div>
                        </div>
                    </div> */}

                    {/* Service 5 */}
                    <div className="col-xl-4" onClick={() => handleClick("WEIGHT LOSS & NUTRITION")}>
                        <div className="service" style={{height: "135px"}}>
                            <div className="icon_container_new">
                                <div className="icon"><MdFace2 size={40} /></div>
                            </div>
                            <div className="service_title">FACIALS</div>
                        </div>
                    </div>
                    <div className="col-sm-4" onClick={() => handleClick("DENTISTRY")}>
                        <div className="service" style={{height: "135px"}}>
                            <div className="icon_container_new">
                                <div className="icon"><GiMedicalDrip  size={40} /></div>
                            </div>
                            <div className="service_title">IV DRIPS</div>
                        </div>
                    </div>
                    {/* Service 6 */}
                    <div className="col-xl-4" onClick={() => handleClick("DENTISTRY")} style={{height: "100px"}}>
                        <div className="service" style={{height: "135px"}}>
                            <div className="icon_container_new">
                                <div className="icon"><FaTooth size={40} /></div>
                            </div>
                            <div className="service_title">DENTISTRY</div>
                        </div>
                    </div>

                   

                    
                    
                  
                    
                    
                   

                    

                </div>
            </div>
        </div>
    )

}
export default ServicesHome