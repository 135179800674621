function Hero() {
    return (

        <div className="container" style={{ marginTop: "50px" }}>
            <div className="row no-gutters">

                {/* Left Column */}
                <div className="col-lg-6" style={{ backgroundColor: "#b99f55", width: "100%" }}>
                    <div className="intro_content" style={{ padding: "10px" }}>
                        <div className="section_title_container">
                            <div className="section_title">
                                <h2 style={{ fontSize: "35px", color: "white", marginTop: "155px" }}>Welcome to Cosmo Health Medical Center</h2>
                                <br />

                                {/* <h2 style={{fontSize: "25px", color: "white"}}>Discover Excellence in Healthcare Across the UAE</h2> */}
                                <p style={{ fontSize: "15px", color: "white" }}>Discover excellence at the best aesthetic clinic in Dubai, with locations in Dubai, Ras El Khaimah, Sharjah, and Ajman. We offer top-tier services in health, beauty, and wellness. Experience the expertise of our cosmetic surgeons and the precision of Invisalign in Dubai.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6" >
                    <img src="images/HeroHome.jpg" style={{ width: "100%", objectFit: "fit" }} />
                </div>


            </div>
        </div>
    )

}
export default Hero