import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function HomeDoctorHero() {
    return (
        <div className="intro">
            <div className="container">
                <div className="row">

                    {/* Left Column */}
                    <div className="col-lg-12">
                        <div className="intro_content">
                            <div className="section_title_container">
                                {/* <div className="section_subtitle">This is Dr Pro</div> */}
                                <div className="section_title"><h3 style={{ color: "#ab7f21" }}>Meet Our Team</h3></div>
                            </div>

                            <br />
                            <h6 style={{ color: "black" }}>
                                Get to know our dedicated team of healthcare professionals who are committed to providing the best care for our patients. Our team consists of highly qualified doctors and staff with expertise in various medical specialties.
                            </h6>
                            {/* <p>
                                Get to know our dedicated team of healthcare professionals who are committed to providing the best care for our patients. Our team consists of highly qualified doctors and staff with expertise in various medical specialties.
                            </p>
                            <br />
                            <h4 style={{ color: "#ab7f21" }}>Join Our Community</h4>
                            <p>
                                Whether you are seeking to enhance your appearance, address a specific health concern, or simply invest in your overall well-being, Cosmo Health Medical Center is here to support you on your journey. Visit us at any of our branches across Dubai, Ras El Khaimah, Sharjah, and Ajman, and discover the difference that compassionate, expert care can make in your life.</p>
                            <br />
                            <p>Embrace a healthier, more vibrant you with Cosmo Health Medical Center – where excellence meets personalized care.</p> */}

                        </div>
                    </div>

                    {/* Right Column */}
                    {/* <div className="col-lg-3 offset-lg-1 py-5">
                        <div className="intro_image"><img src="Cosmo.png" alt="" /></div>
                    </div> */}
                </div>
            </div>
        </div>
    )

}
export default HomeDoctorHero