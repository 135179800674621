import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Appointment from '../Appointment/Appointment';
import { FaBriefcaseMedical } from "react-icons/fa";
import { FaAlignJustify } from "react-icons/fa";
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import logo from './Cosmo.png'
import { FaWhatsapp } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

function Navbar({ onComponentChange, activeComponent }) {


    const aesthetics = [   
        {
            title: "AESTHETIC",
            pageName: "Morpheus8",
            desc: `If you’re seeking a non-surgical solution for reducing unwanted fat in Dubai, you’ve come to the right place. Injection lipolysis is an excellent option for those looking to target small to medium-sized fat deposits that don’t warrant surgical intervention.<br/><br/>
            This procedure involves a series of micro-injections that chemically reduce the number of fat cells around the injection site. The injection contains natural chemicals that are already present in your body, which help to emulsify and break down fat and cholesterol.<br/><br/>
            Injection lipolysis is a safe and effective way to achieve a more contoured and toned appearance without the risks and downtime associated with surgery. So, if you’re looking to enhance your body’s natural contours and achieve a more sculpted look, consider injection lipolysis as your go-to cosmetic procedure.<br/><br/>
            <b>Advantages:</b><br/>
            Injection lipolysis is an excellent solution for those who struggle with stubborn fat cells that refuse to budge despite rigorous exercise and dieting. This non-invasive treatment is highly effective, eliminating the need for extensive preparation, surgery, and post-operative care required for other procedures that yield similar results.<br/><br/>
            What’s more, injection lipolysis is a quick and relatively painless procedure with minimal risks. Patients who undergo this treatment in Dubai can return to their daily routines without any disruption to their normal functioning.<br/><br/>
            Say goodbye to those pesky fat cells and hello to a more confident, streamlined you with injection lipolysis.`
        },
        {
            title: "AESTHETIC",
            pageName: "Non-surgical Nose Job",
            desc: `As the nose is in the centre point of the face, asymmetries can appear more pronounced on and around the nose. In the past, surgical rhinoplasty was the only way to fix nose asymmetry. The new developments in non-surgical aesthetics in recent years can help to resolve several issues that cause insecurity about appearance.<br/><br/>
            The non-surgical nose job, commonly known as non-surgical rhinoplasty, is a treatment that uses dermal filler injections to reshape the nose. By using dermal filler to sculpt and shape the nose, you will get a more pleasing and symmetrical appearance.<br/><br/>
            The non-surgical nose job is done by injecting hyaluronic acid (HA) Dermal Fillers into the nose to temporarily reshape specific nose points, mainly the bridge and tip. The hyaluronic acid filler settles into the injected area and holds the shape to achieve the desired look instantly. This is a perfect temporary solution for issues like a small bump, a drooping nasal tip, and asymmetry. The result usually lasts 12-18 months and is visible after a single treatment.<br/><br/>
            A non-surgical nose job has many impressive results but also some limitations. Fillers can’t physically reduce the size of your nose, straighten or make a wide nose narrower. But they make these issues much less noticeable.<br/><br/>
            The procedure is non-invasive, less aftercare and less recovery time. When considering any non-surgical treatments, it is important to reach a professional doctor to deliver the desired results, minimizing the risks and side effects and avoiding complications.<br/><br/>
            At Cosmo Health Medical Center, we are committed to providing clinically advanced aesthetic treatments with safety and professionalism. We recommend the treatment best suitable for you. Get in touch to book your consultation by clicking here.`
        },
        {
            title: "AESTHETIC",
            pageName: "Double Chin Treatment",
            desc: `A double chin is the excess fat volume gathered under the chin and neck area that looks like a secondary chin. An ill-defined jawline also gives the appearance of a double chin. The visual appearance of a double chin dramatically affects confidence as the people look heavier and older than they actually are. Luckily here at Cosmo Health Medical Center, we have a variety of treatments available to help you finally feel proud of your facial appearance.<br/><br/>
        The excess volume in this area can be a result of many factors like loose skin, excess neck fat, weak chin, drooped salivary gland or sagged platysma muscle. And it can be a combination of any of these that result in a visible double chin, not just excess fat. So an expert’s consultation is needed before fixing treatment.<br/><br/>
        Fat in the neck area is usually difficult to remove despite exercise and dieting. Thankfully, advanced aesthetic medicine has allowed us to treat this stubborn fat without resorting to excessive exercise or surgery. These non-invasive double chin treatment methods, like CoolSculpting and Ultherapy, can take place in a shorter time, involving no downtime and exceptionally long-lasting results.<br/><br/>
        Non-Invasive Face Lift – HIFU<br/><br/>
        HIFU stands for High-Intensity Focused Ultrasound. It is a fat-resolving treatment specially designed to treat smaller areas of fat depositions like double chins. This non-invasive treatment starts to heat cells deep under the skin’s surface with ultrasound energy, promoting the production of more collagen. This boosts collagen, which gives skin its elasticity and firmness, giving skin a tighter appearance with fewer wrinkles and more smoothness.<br/><br/>
        During the procedure, a handheld device is placed on the skin, and the ultrasound energy is focused on specific areas to create thermal coagulation points. The body’s natural healing process then kicks in, resulting in a gradual improvement in the appearance of fine lines, wrinkles, and sagging skin over several weeks to months.<br/><br/>
        One of the main benefits of HIFU is that it is non-invasive and does not require any incisions or anesthesia, making it a safe and relatively comfortable procedure with minimal downtime. It is also suitable for a wide range of skin types and can be used on various areas of the face and body.<br/><br/>
    come to our Medical Center to see our expert surgeons and discuss the best options to achieve your desired shape. Book a consultation today.`
        },
        {
            title: "AESTHETIC",
            pageName: "Fox Eyes",
            desc: `New beauty trends are constantly changing. One of the latest trends that are gaining major popularity within the cosmetic industry is the fox or cat-eye. Foxy eyes are slightly slanted, almond-shaped eyes which are made popular by the likes of supermodels Kendal Jenner and Bella Hadid. Even a new ‘fox eye challenge’ has been trending on social media recently.<br/><br/>
            A fox eye lift is a cosmetic procedure that lifts the outside corners of your eyes and pulls them slightly outwards to give you almond-shaped eyes.<br/><br/>
            Fox Eye Surgery is a minimally invasive treatment using a blunt cannula. The results are rejuvenating as raising the eyebrows also opens the eye and stretches any sagging skin. Depending upon your requirement, we can select any of the following procedures to get the cat eyes you desire.<br/><br/>
            <strong>PDO threading:</strong> The treatment works by inserting a dis-solvable PDO thread under the skin after a local anaesthetic. The inserted thread lifts the outside corner of the eyebrow while stimulating the new collagen production, promoting firmness and elasticity of the skin.<br/><br/>
            <strong>Blepharoplasty:</strong> For people who want a permanent fox eye lift, this is the best option as it removes excess skin and thereby lifts the corner of the eye up. In this procedure, a small portion of the hooded eyelid is cut away. It is a common type of facial plastic surgery, which also reduces the appearance of fine lines and wrinkles.<br/><br/>
            <strong>Temporal lift:</strong> This is a temporary lift achieved through injectable fillers such as Botox. This raises your eyebrows to tighten the skin around the eyes. While most eyebrows have a natural arch, this temporal lift raises the outside corner to lift your eyes naturally. This option typically has no recovery time.<br/><br/>
            <strong>Canthoplasty:</strong> This is a treatment that primarily elongates the eyes. So this option is often used to correct vision problems. When used for fox eye surgery, we make small incisions on the outside corners of the eyes and stretch them slightly to give an almond shape. The procedure can take up to two hours, and the results are permanent.<br/><br/>
            The downtime is usually minimal, with mild swelling and bruising for up to two weeks. Because foxy eyes can be permanent, make sure to get the treatment from a licensed professional such as the ones at Cosmo Health Medical Center. We understand that each patient has individual needs, so we take a customized approach to surgery. All of our practitioners are certified, so you can be confident you will receive exceptional service at  Medical Center.`
        },
        {
            title: "AESTHETIC",
            pageName: "Gummy Smile Treatment",
            desc: `A gummy smile, clinically called excessive gingival display, is when you smile; too much gum tissue shows above the top teeth. Even though a gummy smile is not considered a medical condition, it can affect your self-confidence and discourage you from displaying your pearly whites.<br/><br/>
            Sometimes, a gummy smile can be a result of certain medications. Some medications can cause the overgrowth of your gums. This situation is called gingival hyperplasia.<br/><br/>
            In the past, Gingivectomy, an operation to remove excess gum tissue, was the only way to correct a gummy smile. You can quickly fix your gummy smile in under an hour with advanced technology. Botox Cosmetic injections can be used for a gummy smile correction to give the same effect as gum lift surgery.<br/><br/>
            Botox injections help to relax your facial muscles. For treating a hyperactive upper lip, we use the anti-wrinkle injection to relax the correct muscle, usually near the nose and reduce its ability to pull up your lip excessively. Depending on the muscle treated, the treatment can be as simple with less recovery time. You can go back to normal activities right after.<br/><br/>
            As one of the leading skin clinics, our priority is our safety. If the gummy smile bothers you, book a consultation with one of our dedicated consultants today.`
        },
        {
            title: "AESTHETIC",
            pageName: "Thread Lift Treatment",
            desc: `A thread lift treatment is a non-surgical facial rejuvenation treatment that uses specialized surgical threads to lift sagging skin and give your face a tighter and younger appearance. People who are unsatisfied with the undesirable ageing signs on their faces can get a thread lift without the risk of surgery. Just like the surgical facelift, this will make you look younger and feel better about your facial appearance.<br/><br/>
            The threads used for the treatment are known as PDO Polydioxanone (PDO) threads. These threads are a suture-like material and are carefully placed into the skin with a fine needle to lift the face, reducing the appearance of wrinkles and tightening the skin.<br/><br/>
            The thread lift treatment is not a corrective treatment for just ageing but also stimulates the body’s healing response and thereby triggers collagen production in large amounts. Accelerated collagen production delays ageing, improves skin firmness, volume and texture, and keeps it hydrated and supple.<br/><br/>
            Thread lift is a minimally-invasive cosmetic procedure with minimal recovery time. Owing to the simplicity of the procedure, you can return to your regular life right after the procedure, depending upon the candidate’s condition.<br/><br/>
            Not sure if a PDO thread lift is right for you? Please book a consultation with Cosmo Health Medical Center; our experts will assess your situation and see what treatment is the best fit for you!`
        },
        {
            title: "AESTHETIC",
            pageName: "Anti-Sweating Treatment",
            desc: `Anyone suffering from excessive sweating will understand how it can interfere with their daily activities and the inconvenience caused. Excessive sweating, or hyperhidrosis, is a common problem which affects up to 3% of people. Despite hyperhidrosis’s serious negative impact on those who suffer from it, most never seek treatment. Don’t worry; at Cosmo Health Medical Center, Excess sweating is treated with a simple non-surgical procedure, giving you smooth, dry skin and confidence in your day-to-day life. This non-surgical procedure uses Botox to stop excessive sweating by preventing excess nerve signals in the overactive sweat glands.
            Typically, your sweat glands activate your nervous system when your body temperature rises. As a result, our body automatically cools itself. When you receive Botox injections directly into the affected area, your overactive nerves are partially paralyzed. When your sweat glands stop receiving signals from the nerve, you don’t sweat. 
            This Botox treatment is highly effective and will reduce symptoms by around 90%. This has no downtime, and you can resume your normal activities immediately. The result usually takes 2 and 4 days to notice and two weeks for the full effect. The effects of Botox are temporary, underarm sweating, and booster injections are generally needed after every seven months. For the hands and feet, you may need to repeat your botox treatment after about six months.`
        },
        {
            title: "AESTHETIC",
            pageName: "Lip Lift",
            desc: `Lips are the most defining feature of your face. Shape, volume and facial expressions of lips have a significant impact at first glance on the appearance. Thus, many women dream of having lips that enhance their beauty. If your lips are not as plump or symmetrical as you desired, a lip lift with dermal fillers can deliver that luscious kissable lips.<br/><br/>
            Lip fillers or dermal fillers are substances injected into your lips which remove lines and wrinkles and add volume to lips or cheeks. Most dermal fillers contain a natural hyaluronic acid. First, an anaesthetic cream is used to numb your skin. Then Injections are given around the area of your face being treated and massaged. It might feel uncomfortable but not painful. The treatment usually finishes in 20 and 30 minutes.<br/><br/>
            Fillers are not permanent and last between 6 and 18 months. You can see instant results after the treatment. You can resume your normal activities immediately after the treatment.<br/><br/>
            Lips are the most challenging features for a Nonsurgical Facial treatment as they require significant technical skill. So We suggest a detailed consultation with one of our experienced Doctors to advise you on the outcome and pricing based on your tailored needs. To Book an appointment, call us now.`
        },
        {
            title: "AESTHETIC",
            pageName: "Under Eye Treatment",
            desc: `Eye bags and tired-looking eyes can be caused due to heavy eyelids, puffy eyes, hooded eyes, under-eye dark circles or fine lines under the eyes. Cosmo Health Medical Center offers non-surgical, minimally invasive, safe treatment options for all these concerns and gives you an improved and refreshed appearance.

            Ageing is the most common cause of tired-looking eyes due to loss of collagen and fat padding. Reduced collagen levels result in loss of hydration and elasticity of the associated muscles and skin, leading to sagging and the appearance of wrinkles and fine lines. Such sagging and fine lines are often more prominent under the eyes as the under-eye skin is really thin.
            
            The Under-eye treatment procedure starts with applying local anaesthesia to numb the targeted area to avoid any pain or discomfort. Then, the doctor injects filler containing a gel-like substance to fix dark circles and restore the facial volume. Generally, natural hyaluronic acid is used as this substance to produce a natural-looking outcome. After the fillers are injected, cold packs are applied to minimize the occurrence of side effects like swelling or bruising.
            
            The results of under-eye filler treatment are immediate and last for 1 to 3 years. Fillers need some time to integrate with the surrounding eye tissues and achieve the hydration balance. You will see a lifting effect right after getting this treatment; however, it takes a couple of weeks for the complete desired outcome.
            
            At Cosmo Health Medical Center, we have a number of non-surgical solutions to leave you with an overall rejuvenated eye look.`
        },
        {
            title: "AESTHETIC",
            pageName: "Anti-wrinkle Treatment",
            desc: `Our face shows the signs of ageing first. Age spots, sagging or dry skin, crow’s feet, wrinkles, dark circles, and generally looking tired are all big concerns about ageing skin. Even though we are not able to stop the ageing process, we can help your skin look healthy and reduce the appearance of wrinkles with any of the below-mentioned anti-ageing treatments.

            Dermal fillers are one of the popular anti-ageing treatments in Dubai. These fillers are made from Hyaluronic Acid, which is a natural substance found in our body. Hyaluronic acid’s ability to hold up to 1000 times its own weight in water helps to hydrate the skin. When injected, hydraulic acid is able to plump and volume the skin, which in turn can reduce the appearance of fine lines and wrinkles.
            
            Anti-Wrinkle Cosmetic Injectables are a non-invasive, quick, affordable, and effective way to create a refreshed and youthful look. Wrinkle injections are made from a substance called Botulinum Toxin which can cause a form of muscle paralysis known as botulism. When Small doses are injected into specific areas directly, it causes controlled relaxation of the muscles responsible for wrinkle- formation.
            
            Laser skin resurfacing is another innovative method to rejuvenate ageing skin. It is an ideal treatment to help even out areas of discolouration and can also reduce the appearance of fine lines. The procedure uses short, concentrated pulsating beams of light in a pre-selected pattern on the top layer of the skin.
            
            Choosing the right treatment can be difficult, which is why we have a team of skin experts who assess your skin and suggest the best regime of treatment. All of our skin treatments are performed by highly trained professionals, meaning you are in the safest of hands at all times.To find out the best treatment for you, book a consultation at  Clinic.`
        },
        {
            title: "AESTHETIC",
            pageName: "Hair Treatment",
            desc: `Cosmo Health Medical Center is a destination, focused exclusively on non-surgical facial treatments in Dubai. Our medical experts and luxurious surroundings in medical center create an elevated client experience. All in a setting with elegant interiors and concierge service that is more akin to a five-star hotel.<br/><br/>
            Hair loss in both men and women can lead to a range of emotional issues. We offer a range of regenerative hair loss treatments aiming to restore your confidence.<br/><br/>
            Male pattern baldness, or Androgenetic alopecia, causes hair follicles to shrink, so they produce thinner and thinner hairs until the growth is stopped altogether. Our innovative regenerative hair therapies are designed to stimulate the follicles to become healthier and stronger. Thereby slowing and reversing the thinning process and restoring hair growth. These procedures are non-invasive, so they can easily fit in with the busiest of schedules.<br/><br/>
            <b>Platelet Rich Plasma therapy:</b> Through a set of small injections into the scalp dermis, Platelet Rich Plasma therapy stimulates the follicles so they become healthier and larger, slowing and reversing the thinning process and restoring hair growth.<br/><br/>
            <b>Carboxytherapy:</b> In this procedure, a small amount of carbon dioxide gas are injected below the skin’s surface. This stimulates hair growth by improving circulation, oxygenation and the supply of nutrients to the scalp. Carboxytherapy can combine with other treatments to stimulate and nourish hair follicles.<br/><br/>
            <b>Mesotherapy:</b> With a series of superficial microinjections below the epidermis, the Mesotherapy cocktails target the exact areas of concern, stimulating hair strength and re-growth. The procedure is virtually safe and painless, and no dressing or local anesthesia is required.`
        },
        {
            title: "AESTHETIC",
            pageName: "Fillers",
            desc: `Fillers are gel-like compounds that are injected beneath the skin to replace lost volume, collagen, and elasticity. They plump up areas like your lips or cheeks and provide volume where lines and wrinkles are present. This is a non-invasive way to look younger without having surgery or a downtown.<br/><br/>
            Although fillers are sometimes referred to as “wrinkle fillers,” they actually accomplish much more than that. Additionally, they accentuate sunken cheeks and give them volume again, diminish vertical skin wrinkles, plump the lips, and increase facial symmetry.<br/><br/>
            Fillers don’t last forever. The type of filler used and the area where it is injected are factors that impact how long they last. They often endure 6 to 18 months. There are numerous fillers that have received FDA approval, and they are categorized according to the material they are constructed of. In Dubai, hyaluronic acid, a naturally occurring chemical, is present in the majority of dermal fillers.<br/><br/>
            The average dermal filler procedure lasts 30 minutes. In this procedure, natural fillers called hyaluronic acid are injected directly into the skin to decrease the appearance of wrinkles and improve the overall health of your skin by replenishing moisture where it has been needed. Also, they restore volume lost over time, or provide a subtle enhancement, leaving you feeling confident. A local anesthetic cream will be applied to your skin by your doctor to prepare for the treatment and ease any discomfort. Although there may be a small amount of localized discomfort, the injections shouldn’t hurt.<br/><br/>
            Filler-based procedures are secure and fast. This does not imply that they are risk-free, especially if they are carried out by unqualified practitioners. At Cosmo Health Medical Center Our cosmetic physicians are qualified to provide youthful and natural-looking outcomes while attempting to minimize complications.`
        },
        {
            title: "AESTHETIC",
            pageName: "Butt Fillers",
            desc: `The gluteal muscles and the fat that covers them are what give the buttocks their characteristic contour. Walking, squats, and climbing are some examples of routine activities that target these muscles and can help tone, lift, and build muscle to assist attain an apple-shaped posterior.

            However, sagging skin and cellulite are very difficult to correct with exercise alone and may require a little aesthetic assistance to achieve the full and lifted look.
            
            The non-surgical butt lift procedure Sculptra or Lanluma bum lift is particularly well-liked. Both Sculptra and Lanluma are injectable products that encourage the production of collagen and elastin in your body, while Lanluma is newer and has a more pronounced plumping effect. By filling in wrinkles and cellulite, Lanluma injections help to improve the appearance of the skin. They also help to increase the volume to the buttock region. You will probably require 2-3 rounds of bum injections, spaced one month apart. It will take 3-6 months for the full effects to show, and they will persist for two years.
            
            Moreover, Sculptra is different from other buttock fillers like hyaluronic acid. In order to give you fuller, firmer, and more aesthetically pleasing buttocks, Sculptra leverages the strength of your own collagen rather than synthetic filler.
            
            Although the effects of bum injections are not permanent (lasting approximately two years on average), deciding to have any kind of aesthetic operation is a serious decision that should be given some considerable thinking and consideration. Please do not hesitate to contact us if you have any questions regarding bum fillers or to schedule your consultation.`
        },
        {
            title: "AESTHETIC",
            pageName: "Fat Dissolving Injections",
            desc: `If you’re seeking a non-surgical solution for reducing unwanted fat in Dubai, you’ve come to the right place. Injection lipolysis is an excellent option for those looking to target small to medium-sized fat deposits that don’t warrant surgical intervention.<br/><br/>
            This procedure involves a series of micro-injections that chemically reduce the number of fat cells around the injection site. The injection contains natural chemicals that are already present in your body, which help to emulsify and break down fat and cholesterol.<br/><br/>
            Injection lipolysis is a safe and effective way to achieve a more contoured and toned appearance without the risks and downtime associated with surgery. So, if you’re looking to enhance your body’s natural contours and achieve a more sculpted look, consider injection lipolysis as your go-to cosmetic procedure.<br/><br/>
            <b>Advantages:</b><br/>
            Injection lipolysis is an excellent solution for those who struggle with stubborn fat cells that refuse to budge despite rigorous exercise and dieting. This non-invasive treatment is highly effective, eliminating the need for extensive preparation, surgery, and post-operative care required for other procedures that yield similar results.<br/><br/>
            What’s more, injection lipolysis is a quick and relatively painless procedure with minimal risks. Patients who undergo this treatment in Dubai can return to their daily routines without any disruption to their normal functioning.<br/><br/>
            Say goodbye to those pesky fat cells and hello to a more confident, streamlined you with injection lipolysis.`
        }


    ]

    const LaserTreatment = [
        {
            title: "LASER TREATMENT",
            pageName: "Laser Hair Removal",
            desc: `Throw out the razors! Our Laser hair removal is a long-lasting form of unwanted hair removal by exposure to pulses of laser light that destroy the hair follicle. Hair Growth follows a unique cycle that involves resting, shedding, and growing periods. Recently removed hairs will be in a resting phase and might not be visible to the laser. In this case, you may need to wait until it regrows before removing it. Generally, laser hair removal requires several treatments over the course of 2 to 3 months.<br/><br/>
            No more waxing! Repeatedly shaving or waxing a body part can cause skin pigmentation and uneven skin tone. Laser hair removal technology reduces the risk of this happening.<br/><br/>
            With sensitive skin in mind, our Laser Hair Removal technology is safe to be used on any body part. It targets different skin types while ensuring maximum results. Unlike all the other hair removal options, our medical-grade Laser Hair Removal is fast, safe, reliable, and provides permanent hair reduction. This market-leading laser technology caters to all skin types, tones, and genders.<br/><br/>
            The recommended initial course of Laser Hair Removal treatment is approximately 8-12 treatments, spaced 4-6 weeks apart depending on the skin type and area. Everyone is slightly different, so we suggest booking a consultation with one of our Laser Therapists at  Medical Center.`
        },
        {
            title: "LASER TREATMENT",
            pageName: "Laser Vein Removal",
            desc: `Spider veins or Leg thread veins are harmless but annoying veins that appear on the surface of your skin. Even though these veins are usually not painful, but may negatively affect your confidence, particularly in summer when you want to bare your legs.

            As we age, some valves become weaker, so we are more likely to develop leg thread veins. Many people suffering from leg thread veins will find it genetic. This condition is more common in women. Overweight, Pregnancy, Hormones or sitting/ standing for extended periods can be the other reasons.
            
            One of the popular treatment options for leg thread veins is the use of a laser. This will effectively break down unwanted veins using brief pulses of laser light. This light heats up the veins very quickly, causing them to collapse in on themselves. Laser treatment is usually suggested for small veins.
            
            In your consultation, the best treatment will be suggested depending on the size and number of thread veins you have. Some people see the results after one treatment, whereas others will need a course of three to see optimum results.`
        },
        {
            title: "LASER TREATMENT",
            pageName: "Mole Removal",
            desc: `Look Good. Feel Great.<br/><br/>
            Moles are a common feature of our skin caused by a collection of pigment-producing cells called melanocytes, and moles can vary in colour, size or shape. Most moles are harmless and tend to get more as we age. Abnormal mole tends to proliferate and have different sizes or shapes and a darker colour than your other moles.<br/><br/>
            Mole removal is a quick and pain-free procedure for removing cosmetic blemishes or moles under local anaesthetic with no cancer risk. Mole removal can be either a laser and/or surgical technique to ensure that all suspicious, worrying or unsightly moles are safely removed.<br/><br/>
            At Fine Cosmetics, We offer a range of methods for mole removal. The most suitable method can be used for each individual, decided at your consultation.<br/><br/>
            
            <ul>
                <li><b>Shave Excision:</b> This is a suitable method for a protruding mole; minimally invasive, and no stitches are required after the procedure. This method can leave a round pinkish scar where the mole has been cut out.</li>
                <li><b>Curette and Cautery:</b> In this method, a wire with a small electrical charge is used to burn the mole away. Minimally invasive, no stitches required. Just like the shave excision, it can leave a round pinkish scar where the mole has been cut out.</li>
                <li><b>Elliptical Surgical Excision:</b> Under local anaesthetic, the mole is cut away surgically. This is slightly more invasive than the other two. Stitches are required, and you must attend the clinic seven days later to remove the stitches. A fine white line scar may appear where the mole has been cut out.</li>
            </ul>`
        },
        {
            title: "LASER TREATMENT",
            pageName: "Laser Hair Bleaching",
            desc: `Make unwanted hair invisible without chemicals.<br/><br/>
            Finding a safe solution to get rid of unwanted hair is something many of us have looked for, trying everything from waxing or shaving. But, many of these methods can result in irritated or uneven skin tone and may leave you feeling self-conscious.<br/><br/>
            Laser Hair Removal is considered the most popular way to deal with unwanted hair quickly and effectively. However, there are times when laser hair removal is not the best solution. Specific hairs– for instance, fine baby hairs–are difficult to remove using the laser. Lasers can be effectively used to bleach these hairs and make them invisible for several weeks at a time.<br/><br/>
            Laser hair bleaching targets the melanin in fine baby hair and thereby reduces the amount of melanin in that fine hair, making them invisible. Laser hair bleaching technology uses Photo Acoustic energy. Short pulses of laser light penetrate the hair follicle and disrupt the factors causing hair growth. Unlike the other laser hair removal method for thick hair, you don’t need to shave the area, but the hair cannot be any longer than 2cm.<br/><br/>
            This procedure is non-surgical and non-invasive, so you don’t experience any kind of irritation or breakouts. It is super quick and takes only 15-45 minutes to complete. Like all other Laser therapy treatments, the frequency and number of treatments will depend on your skin, area, and the size of that area. Our specialists at Cosmo Health Medical Center will have an in-depth consultation to help create a personalised treatment plan. Book your consultation today.`
        },
        {
            title: "LASER TREATMENT",
            pageName: "Laser Hair Bleaching",
            desc: `The laser carbon peel is a fast and painless procedure for improving the appearance of the skin. It’s the best solution for people with oily skin, acne, and enlarged or clogged pores. This treatment is also beneficial for the effects of sun damage or tan on your skin.

            Resurfacing treatments can be classified in three ways: superficial, medium, and deep depending on how many layers of skin the treatment penetrates. Treatments that penetrate further below the surface of the skin show more dramatic results. So superficial treatments deliver modest results with minimal recovery time.
            
            One of the popular options for mild to moderate skin issues is a carbon laser peel or carbon laser facials. Carbon laser peel is a method of superficial treatment that helps with acne, enlarged pores, oily skin, and uneven skin tone.
            
            Despite what the name suggests, a carbon laser peel is not just a traditional chemical peel. Instead, our doctor uses a carbon solution and lasers to create a peeling effect on your skin. The first step is to apply a high carbon content serum to your skin. As the serum dries, it bonds with the impurities like dirt, oil, and other contaminants on the surface of the skin. Depending on the skin type, doctors pass a warming laser over your face, which will heat the carbon to absorb the impurities on your skin. In the final step, this carbon serum is broken down using a pulsed laser. The laser destroys the carbon particles and the bonded oil, dead skin cell, bacteria, or other impurities. In addition, the heat from this process also signals a healing response in your skin. That deeply exfoliate the skin and stimulates collagen and elastin production to make your skin look firmer. Since The lasers don’t penetrate the skin too deeply, there is a fast recovery time. The treatment is quick and takes about 30 minutes only.`
        },
        {
            title: "LASER TREATMENT",
            pageName: "Laser Carbon Peel",
            desc: `The laser carbon peel is a fast and painless procedure for improving the appearance of the skin. It’s the best solution for people with oily skin, acne, and enlarged or clogged pores. This treatment is also beneficial for the effects of sun damage or tan on your skin.

            Resurfacing treatments can be classified in three ways: superficial, medium, and deep depending on how many layers of skin the treatment penetrates. Treatments that penetrate further below the surface of the skin show more dramatic results. So superficial treatments deliver modest results with minimal recovery time.
            
            One of the popular options for mild to moderate skin issues is a carbon laser peel or carbon laser facials. Carbon laser peel is a method of superficial treatment that helps with acne, enlarged pores, oily skin, and uneven skin tone.
            
            Despite what the name suggests, a carbon laser peel is not just a traditional chemical peel. Instead, our doctor uses a carbon solution and lasers to create a peeling effect on your skin. The first step is to apply a high carbon content serum to your skin. As the serum dries, it bonds with the impurities like dirt, oil, and other contaminants on the surface of the skin. Depending on the skin type, doctors pass a warming laser over your face, which will heat the carbon to absorb the impurities on your skin. In the final step, this carbon serum is broken down using a pulsed laser. The laser destroys the carbon particles and the bonded oil, dead skin cell, bacteria, or other impurities. In addition, the heat from this process also signals a healing response in your skin. That deeply exfoliate the skin and stimulates collagen and elastin production to make your skin look firmer. Since The lasers don’t penetrate the skin too deeply, there is a fast recovery time. The treatment is quick and takes about 30 minutes only.`
        },
        {
            title: "LASER TREATMENT",
            pageName: "Fractional Radiofrequency",
            desc: `Photodamaged and ageing skin is associated with a decrease in collagen levels resulting in fine lines, wrinkles, tone and texture changes, and lax skin. The major reason for this is thinning of the epidermis and dermis. Damage to the dermis affects the activity of various enzymes, such as metalloproteinases and collagenase, which are responsible for removing the old tissue and deposition of new.

            Fractional radiofrequency (FRF) has recently emerged for the treatment of scars, cellulite and skin rejuvenation. FRF works by causing minimal to no damage to the skin, naturally boosting collagen production and rejuvenating skin cells, resulting in the stimulation of cell regeneration and tightening of the skin.
            
            This treatment can be used to treat all areas of the body except the lips and eyelids due to the high sensitivity of the areas.
            
            Fractional RF micro-needling is a minimally invasive technology using a set of very fine sterilized needles to create microscopic wounds at various predetermined depths in the dermis without affecting the epidermis. The direct thermal heating of the dermis results in new collagen production and subsequently results in dermal thickening. The presence of the intact epidermis and the surrounding dermal tissue results in rapid re-epithelialization and thus minimal downtime and risks. Recent studies show that fractional radiofrequency micro-needling is effective in the treatment of a majority of skin problems.
            
            After assessing your skin, we recommend the optimum number of treatments required to achieve noticeably healthier young skin. One single section of treatment takes between 20 to 45 minutes, although this is completely dependent on the size of the area being treated`
        },
    ]

    const wellness = [
        {
            title: "WELLNESS",
            pageName: "Health Checkup",
            desc: `Everyone wants to stay healthy and enjoy a long and happy life without any significant disease. Sadly, Cancer and Coronary Heart Disease can strike unannounced at any time. However, there is something you can do about it. You can have a complete body check, and if anything is developing inside, you can get it treated.<br/><br/>
            A complete body check-up helps detect the earliest signs and risk factors for any disease and gives you the tools to be proactive about your health. Prevention is key. Cosmo Health Medical Center will help you understand your results and provide the knowledge and medication to tackle health issues, adopt a healthy lifestyle, and reduce your risk of future disease.<br/><br/>
            Book yourself a Healthy Heart Screening package at Cosmo Health Medical Center and give your heart the care it needs.<br/><br/>
            BOOK TODAY AND AVAIL THE FOLLOWING PACKAGE:<br/>
            - Consultation with physician<br/>
            - ECG<br/>
            - Blood Pressure<br/>
            - Lipid Profile<br/>
            - Blood Sugar<br/>
            - BMI`
        },
        {
            title: "WELLNESS",
            pageName: "Oligo Scan Analysis",
            desc: `A quick and precise way to analyze trace elements, vitamins, minerals, heavy metals, and oxidative stress is to use the OligoScan. It uses an optical technology called spectrophotometry to obtain results on the number of trace elements and heavy metals that exist within the specific area of body tissue. The scanner measures the intensity of the electromagnetic signals of 34 elements, after which the intelligent software calculates the results of deficiencies and accumulations and compiles a comprehensive report.<br/><br/>
            Why do we need OligoScan?<br/>
            Minerals play a key role in the proper functioning of the body. A deficiency in trace elements, vitamins, and minerals result in tiredness, poor performance, stress, tension, or intellectual capacity. So it’s very important to control the trace elements in the body to be able to anticipate associated risks.<br/><br/>
            OligoScan can first assist us in determining our supplement and detoxification needs. Deficiencies in some nutrients or an accumulation of heavy metals in the blood can have a remarkable number of negative effects on one’s health. This indicates that OligoScan can be particularly beneficial for people with frequent problems like:<br/><br/>
            - Problematic skin, sensitive skin, and acne<br/>
            - Allergies/sensitivities<br/>
            - Difficulty sleeping<br/>
            - Gaining weight<br/>
            - Feeling tired<br/>
            - Limited immunity<br/>
            - Low mood<br/><br/>
            <strong>Advantages:</strong><br/>
            - Non-Invasive and pain-free<br/>
            - No blood test or Injection is needed<br/>
            - More accurate than a standard blood test<br/>
            - Rapid complete Written Report<br/>
            - Level of cellular oxidation and cellular ageing<br/>
            - Diabetes, insulin resistance, thyroid illness, and allergy susceptibility<br/>
            - Hormonal level<br/>
            - Liver detox level<br/>
            - Detection of the high rates of toxic metals in the body<br/>
            - Anti-oxidant levels<br/><br/>
            <strong>OligoScan Analysis is completely free of charge if you proceed with any IV treatment on the same day.</strong><br/><br/>
            Our medical professionals can make recommendations based on the report that will be highly compatible and suitable for your existing deficiencies.`
        },
        {
            title: "WELLNESS",
            pageName: "Slimming & Body Contouring",
            desc: `Weight Loss is not just about Calories and Exercise. Our approach allows identifying the underlying causes which must be addressed before treating weight loss. This approach not only allows you to lose weight but, also importantly helps you to achieve overall health.<br/><br/>
            Cosmo Health Medical Center uses leading, clinically proven advanced technologies to deliver comfortable and non-invasive treatments for skin tightening, fat reduction, total body contouring, and successful cellulite treatment.<br/><br/>
            <strong>Treatments:</strong><br/>
            <ul>
                <li>- Inch Loss</li>
                <li>- Fat reduction</li>
                <li>- Skin tightening</li>
                <li>- Buttock lifting</li>
                <li>- Cellulite</li>
                <li>- Total body contouring</li>
            </ul>
            Interested in learning more about how our treatment can be customized to help you meet your personal goals? Book a call now.`
        },
    ]

    const facialTreatment = [
        {
            title: "FACIAL TREATMENT",
            pageName: "Hydrafacial",
            desc: `HydraFacial is an ultimate skin detoxifying and deep cleansing experience for fresh, rejuvenated skin, using active botanical ingredients. This facial treatment reveals glowing, refreshed and radiant skin with immediate results. HydraFacial is suitable for all skin types and concerns at any age.<br/><br/>     
            Hydrafacial will instantly and visibly renew your skin and stimulate collagen production. It’s not just another facial but a unique mix of technology that will unleash a new layer of glowing, nourished skin with the 6-step method. This six-step treatment process follows – Detox, Cleanse and Exfoliation, Brightening, Extraction, Hydration and finally, Rejuvenation. Dead skin cells and impurities are removed after the process, and the skin is hydrated with antioxidants and peptides.<br/><br/>   
            The technology uses advanced, vortex technology and effectively delivering botanical ingredients containing nutrients like hyaluronic acid, red algae extract, horse chestnut seed extract, zinc and magnesium peptides. HydraFacial also delivers powerful antioxidants which counteract damage from the free radicals which accelerates the ageing process.<br/><br/>           
            HydraFacial helps to purify, extract, and moisturise the damaged skin. Whether you’re simply looking for healthy skin or are concerned about any particular skin condition, HydraFacial has the best solution. Book an appointment with us today.`
        },
        {
            title: "FACIAL TREATMENT",
            pageName: "Microneedling",
            desc: `Microneedling is a revolutionary cosmetic procedure that can help you achieve flawless, youthful-looking skin. This minimally invasive treatment stimulates collagen development, which is essential for maintaining healthy skin. It is also known as collagen induction therapy and is highly effective in treating a range of skin problems, including stretch marks, acne scars, sunspots, and even eyelid surgery.<br/><br/>       
            The procedure works by creating tiny pinpricks in the skin, which cause minor damage. This damage triggers the body’s natural healing process, resulting in the production of new collagen-rich tissue. The new tissue has a more uniform texture and tone, giving your skin a firmer, more youthful appearance.<br/><br/>        
            As we age, our skin loses collagen, which can lead to wrinkles, fine lines, and other signs of aging. Microneedling is an excellent way to combat this natural process and keep your skin looking its best. It is a safe and effective treatment that can be customized to meet your specific needs.<br/><br/>         
            If you’re interested in microneedling, we invite you to book a consultation at Cosmo Health Medical Center in Dubai. Our team of highly skilled medical professionals will work with you to develop a personalized treatment plan that will help you achieve the results you want. Don’t wait any longer to get the flawless, youthful-looking skin you deserve. Contact us today to schedule your consultation!`
        },
        {
            title: "FACIAL TREATMENT",
            pageName: "Acne Scar Removal",
            desc: `Acne can be very distressing, but the worst part is that even after the acne has cleared, there may have some pigmentation and scarring on your face. According to the latest data, with a prevalence rate of 1 in 5 people, 95% of acne sufferers say it impacts their daily lives. Don’t worry; we are here to help. Find a solution to your acne scarring and discover confidence in better skin at Cosmo Health clinic, Palm Jumeriah.<br/><br/>
            Scars are formed when a breakout punctures the skin profoundly and destroys the tissues under it. Before treating the scars, it’s vital to identify their type. Each type responds to treatment differently; some methods work better for particular types than others. There is a range of acne scar treatments available at our clinic, depending on the type and severity of the scarring.<br/><br/>
            At Cosmo Health, various treatments are available to help you get rid of embarrassing acne scars. These include microdermabrasion, chemical peels, dermal fillers, and fractional CO2 lasers. Determining which treatment will be best for you is determined by our dermatologist after evaluation. The final results will take some time to appear, depending on the severity and type of scars. The results will be long-lasting.`
        },
        {
            title: "FACIAL TREATMENT",
            pageName: "Chemical Peels",
            desc: `A chemical peel is a procedure in which a chemical solution is applied to the face, hands, or neck to improve the appearance of the skin. This chemical solution results in the skin exfoliation and eventually peeling off. After the treatment, the new skin grows back and is often smoother, appears less wrinkled, and has less damage.<br/><br/>
            There are three types of peels: superficial, medium, and deep.<br/><br/>         
            Superficial and medium peels are usually safe and have less recovery time. These two peels are not permanent and need to be repeated for longer results. Deeper peels are more risky. The result is longer-lasting and usually does not need to be repeated.<br/><br/>          
            In a light peel, a chemical solution like salicylic acid will be applied to the area using a cotton ball or brush. Once the procedure is complete, the chemical solution is removed, or a neutralizing solution is added.<br/><br/>   
            During the medium chemical peel, a chemical solution containing glycolic acid or trichloroacetic acid is applied to the skin. The skin will begin to whiten, and a cool compress will be applied to the skin.<br/><br/>
            During a deep chemical peel, a cotton-tipped applicator will be used to apply phenol to your skin, which turns your skin white or grey. The procedure is done in 15-minute sections to limit the skin’s exposure to the acid.<br/><br/>
            A thorough evaluation by a professional dermatologist is imperative before a chemical peel. At Finecare, we offer a variety of industry-leading skin peels that exfoliate dead skin cells and encourage new ones to grow, revealing the softer, smoother skin beneath.`
        },
        {
            title: "FACIAL TREATMENT",
            pageName: "Carbon Laser Facial",
            desc: `The Carbon Laser Facial is a non-invasive and painless treatment that typically requires no downtime. The procedure involves the application of liquid carbon to the face, which bonds with the skin and any impurities such as open pores or acne. Once the carbon has dried, a laser is passed across the skin. The laser is attracted to the carbon particles, which ultimately destroys the particles in the pores or acne.<br/><br/>
            This treatment is an excellent option for those seeking a quick and effective solution to skin imperfections. It is a safe and efficient way to achieve a smoother, more radiant complexion without the need for invasive procedures or extended recovery time.<br/><br/>
            The Carbon Laser Facial is suitable for all skin types and can be customized to address specific concerns. Whether you are looking to reduce the appearance of fine lines and wrinkles, minimize pores, or improve overall skin texture, this treatment can help you achieve your desired results.<br/><br/>
            In conclusion, the Carbon Laser Facial is an effective method of skin rejuvenation and is an excellent option for anyone looking to achieve a more youthful, radiant complexion.<br/><br/>
            It is crucial to bear in mind that an incorrect laser procedure can result in long-term damage to your skin. At Cosmo Health, we prioritize your comfort and satisfaction above all else. Our team of highly skilled professionals takes great care to perform every procedure with meticulous attention to your well-being. As a result, we offer a customer experience that is unparalleled in the industry.`
        },
        {
            title: "FACIAL TREATMENT",
            pageName: "Carbon Laser Facial",
            desc: `The Carbon Laser Facial is a non-invasive and painless treatment that typically requires no downtime. The procedure involves the application of liquid carbon to the face, which bonds with the skin and any impurities such as open pores or acne. Once the carbon has dried, a laser is passed across the skin. The laser is attracted to the carbon particles, which ultimately destroys the particles in the pores or acne.<br/><br/>
            This treatment is an excellent option for those seeking a quick and effective solution to skin imperfections. It is a safe and efficient way to achieve a smoother, more radiant complexion without the need for invasive procedures or extended recovery time.<br/><br/>
            The Carbon Laser Facial is suitable for all skin types and can be customized to address specific concerns. Whether you are looking to reduce the appearance of fine lines and wrinkles, minimize pores, or improve overall skin texture, this treatment can help you achieve your desired results.<br/><br/>
            In conclusion, the Carbon Laser Facial is an effective method of skin rejuvenation and is an excellent option for anyone looking to achieve a more youthful, radiant complexion.<br/><br/>
            It is crucial to bear in mind that an incorrect laser procedure can result in long-term damage to your skin. At Cosmo Health, we prioritize your comfort and satisfaction above all else. Our team of highly skilled professionals takes great care to perform every procedure with meticulous attention to your well-being. As a result, we offer a customer experience that is unparalleled in the industry.`
        },
        {
            title: "FACIAL TREATMENT",
            pageName: "Cleopatra Facial",
            desc: `When it comes to beauty, Cleopatra has always been regarded as one of the most beautiful women ever lived. Luckily, her beauty secrets are well documented, which has inspired the Cosmetic world to take new steps in beauty treatments.<br/><br/>
            
            Cleopatra facial is a pampering, nourishing, resurfacing, and skin brightening cosmetic treatment, utilizing the skin rejuvenating properties of lactic acid and gold. The 24K Cleopatra Gold Sheets enrich the skin with the luminous benefits of gold, accelerating the skin’s natural radiance and removing unwanted facial lines. For skin tightening, the treatment combines a 24K gold sheet mask with a light chemical peel, radio frequency or ultrasound treatment, and LED therapy on areas of concern.<br/><br/>
            
            This unique anti-ageing facial has three steps: first, a mild chemical peel, then dermaplaning (or facial shaving), followed by a vitamin facial.<br/><br/>
            
            Cleopatra facials give instant results, with the best results seen after a couple of days. A significant improvement in the complexion and smooth, clear skin can be visible after the treatment. The skin will look refreshed and rejuvenated for several weeks; however, regular treatments are advised for maximum results.`
        },
        {
            title: "FACIAL TREATMENT",
            pageName: "Microneedling",
            desc: `Microneedling is an effective, minimally invasive therapeutic technique that helps to address many skin-related complaints, like acne scars, burn scars, skin rejuvenation, hyperhidrosis, wrinkles, stretch marks, and many more. This is considered a very safe technique for dark skin types, where the risk of post-inflammatory pigmentation is high with many other techniques that damage the epidermis.<br/><br/>
        
            Microneedling is a relatively new procedure involving superficial and controlled skin puncturing by rolling with fine miniature needles. It is a fast procedure lasting 10 to 20 minutes depending on the area to be treated. Preferably, the skin should be prepared preoperatively for at least a month with vitamin A and C formulations twice a day for maximum dermal collagen formation.<br/><br/>
        
            There is little downtime, and the patient can resume daily work the very next day. Treatments are performed at 3–8 week intervals, and multiple sittings are needed to achieve the desired effect on the skin. The final results cannot be viewed immediately because new collagen production takes approximately 3–6 months after treatment has ceased.<br/><br/>
        
            At Finecare Clinic, we like to go the extra mile for creating a tailored and personalised treatment to maximise your treatment results. All appointments begin with a careful consultation and assessment of your skin. Then we will thoroughly explain the procedure and benefits prior to treatment. Book your appointment today for beautiful skin.`
        },
        {
            title: "FACIAL TREATMENT",
            pageName: "Topical Gold Threads",
            desc: `Gold filament rejuvenation is another name for the application of gold threads for skin renewal.<br/><br/>
        
            The gold threads stimulate the body’s natural development of new collagen (neocollagenesis) in response to a controlled trauma or foreign body reaction, similar to other cosmetic procedures like medical microneedling. As a result, the skin becomes thicker and generally a lot more supple. In certain persons, it may also have a modest tightening or lifting effect. However, compared to therapies like skin needling, the effects of gold filament treatment are far more long-lasting.<br/><br/>
        
            By thickening the skin through this neocollagenesis process, the treatment aims to increase its elasticity, firmness, and suppleness. Even though they are considered a more long-lasting choice than some thread or suture products, slow thread fragmentation may occur with partial resorption by phagocytosis, when the body gradually absorbs the threads. The new collagen structure will stay in place and the person won’t notice this.`
        },



    ]

    const dental = [
        {
            title: "DENTAL",
            pageName: "Cosmetic Dentistry",
            desc: `With just a small change in your smile and nothing else, you would find a dramatic transformation in your personality, confidence, and appearance. Cosmetic dentistry is a set of different treatments designed to deliver you the perfect smile – from teeth whitening to complete smile makeovers.<br/><br/>

            One of our popular treatments is the teeth whitening procedure, which can help to improve your smile’s appearance for a healthier-looking, brighter set of teeth in no time! When it comes to Cosmetic Dentistry, teeth whitening is the most effective, affordable, and speedy way to transform your teeth’ aesthetic. Moreover, it requires little to no downtime and gets impressive results with minimal sensitivity!<br/><br/>
        
            Veneers are a quick and effective solution for misaligned, stained, or chipped teeth. They also cover up gaps where orthodontics isn’t suitable. In this treatment, a thin, custom-made porcelain moulding will be bonded to the front surface of a tooth. Veneers offer a natural look, as they have the same colour, shape, and size as your original teeth.<br/><br/>
        
            Another popular cosmetic dentistry option is a smile makeover, which gives you an opportunity to exclusively design your perfect smile.<br/><br/>
        
            We can assure you that at Cosmo Health Medical Center Dubai, you will be examined by a highly qualified specialist who has an artistic eye that makes them the perfect choice to create a naturally perfect pleasing smile.`
        },
        {
            title: "DENTAL",
            pageName: "General Dentistry Treatment",
            desc: `General dentistry refers to diagnosing, preventing, and treating various conditions, diseases, and disorders that may affect your teeth, gums, and jawbone. It involves regular check-ups, digital X-rays, and cleaning or polishing of your teeth on a daily basis. During the examination, the pictures of your teeth are placed on a screen to let you know about the condition and treatments you need. Dentists provide oral health care and periodontal maintenance for patients of all ages.<br/><br/>
    
            Cosmo Health Medical Center brings together the highest standards of professionalism and excellence in general dentistry within a clean, comfortable, and safe environment. Our highly skilled and experienced dentists provide the best possible care for each patient.  is well equipped with the latest technology, so it offers an advanced level of treatment.<br/><br/>
            
            <ul>
                <li>- Teeth sensitivity to hot or cold</li>
                <li>- Gums that bleed while brushing your teeth</li>
                <li>- Bad breath</li>
                <li>- Bad taste in mouth</li>
                <li>- Pain when chewing</li>
                <li>- Any medical condition that risks your oral health.</li>
                <li>- Maintenance of restorative work such as crowns, fillings, or dental implants, and Braces (Invisalign).</li>
            </ul><br/><br/>
            
            Get in touch with us to book an appointment.`
        },
        {
            title: "DENTAL",
            pageName: "Dental Implant",
            desc: `A lot of people have been suffering from loose dentures, multiple missing teeth, or worn & broken-down teeth. The perfect solution for this is dental implants. Finecare Clinic offers a wide range of implant and surgical treatments to transform a patient’s smile and help to get their confidence back again.<br/><br/>
    
            A dental implant is an artificial tooth root placed into a jaw to hold a replacement tooth or bridge. It has long been considered the treatment of choice for missing teeth. This is because dental implants are a permanent, long-lasting, and fixed solution for missing teeth; Implants can replace & restore missing teeth without cutting down healthy teeth on either side of the gaps.<br/><br/>
            
            Dental Implants are tiny titanium screws placed into the jawbone to act as the replacement of tooth roots. The new artificial tooth will be secured in place onto the implant anchor or false ‘root’.<br/><br/>
            
            If you are missing multiple teeth, implant-supported dentures can be the perfect solution. Other common treatments like fixed bridges or removable partial dentures are dependent on support from adjacent teeth. But these implant-supported bridges replace teeth without sacrificing the health of neighboring teeth, in addition to looking and functioning like natural teeth.<br/><br/>
            
            Book a consultation with us today and get more about the benefits of dental implants and all the treatment options available to you.`
        },
        ,
        {
            title: "DENTAL",
            pageName: "Pediatric Dentistry",
            desc: `A lot of people have been suffering from loose dentures, multiple missing teeth, or worn & broken-down teeth. The perfect solution for this is dental implants. Finecare Clinic offers a wide range of implant and surgical treatments to transform a patient’s smile and help to get their confidence back again.<br/><br/>
    
            A dental implant is an artificial tooth root placed into a jaw to hold a replacement tooth or bridge. It has long been considered the treatment of choice for missing teeth. This is because dental implants are a permanent, long-lasting, and fixed solution for missing teeth; Implants can replace & restore missing teeth without cutting down healthy teeth on either side of the gaps.<br/><br/>
            
            Dental Implants are tiny titanium screws placed into the jawbone to act as the replacement of tooth roots. The new artificial tooth will be secured in place onto the implant anchor or false ‘root’.<br/><br/>
            
            If you are missing multiple teeth, implant-supported dentures can be the perfect solution. Other common treatments like fixed bridges or removable partial dentures are dependent on support from adjacent teeth. But these implant-supported bridges replace teeth without sacrificing the health of neighboring teeth, in addition to looking and functioning like natural teeth.<br/><br/>
            
            Book a consultation with us today and get more about the benefits of dental implants and all the treatment options available to you.`
        },
        {
            title: "DENTAL",
            pageName: "Invisalign",
            desc: `A lot of people have been suffering from loose dentures, multiple missing teeth, or worn & broken-down teeth. The perfect solution for this is dental implants. Finecare Clinic offers a wide range of implant and surgical treatments to transform a patient’s smile and help to get their confidence back again.<br/><br/>
    
            A dental implant is an artificial tooth root placed into a jaw to hold a replacement tooth or bridge. It has long been considered the treatment of choice for missing teeth. This is because dental implants are a permanent, long-lasting, and fixed solution for missing teeth; Implants can replace & restore missing teeth without cutting down healthy teeth on either side of the gaps.<br/><br/>
            
            Dental Implants are tiny titanium screws placed into the jawbone to act as the replacement of tooth roots. The new artificial tooth will be secured in place onto the implant anchor or false ‘root’.<br/><br/>
            
            If you are missing multiple teeth, implant-supported dentures can be the perfect solution. Other common treatments like fixed bridges or removable partial dentures are dependent on support from adjacent teeth. But these implant-supported bridges replace teeth without sacrificing the health of neighboring teeth, in addition to looking and functioning like natural teeth.<br/><br/>
            
            Book a consultation with us today and get more about the benefits of dental implants and all the treatment options available to you.`
        },
    ]

    const ivDrip = [
        {
            title: "IV DRIP",
            pageName: "Immune Booster IV Drip",
            desc: `Stress, lack of exercise, exposure to sunlight, lack of sleep, tobacco, and alcohol suppress the immune system and expose us to infections and chronic diseases. Immune Booster IV drip is the latest IV drip that boosts your immune system with Vitamin C, B12, Glutathione, and Electrolytes.<br/><br/>
                   IV Immune Booster of these vitamins & nutrients is much more effective than taking oral supplements. Only 10% of the vitamins from oral supplements are absorbed in the gastrointestinal tract. With the Immune booster, the key nutrients are delivered into your body 100% and immediately.<br/><br/>
                   <strong>Advantages:</strong><br/>
                   - Help your immune system to be in the best condition.<br/>
                   - Reduces tiredness, stress, and fatigue.<br/>
                   - Decreases oxidative stress caused by free radicals.<br/><br/>
                   Our IV Immunity Booster helps your body to recover full health.`
        },
        {
            title: "IV DRIP",
            pageName: "Anti-hair Loss Drip",
            desc: `Hair is often a reflection of our identity, signifying self-confidence and self-care. If your hair looks overly oily or dry and frizzy, then it can have a significant impact on your self-esteem.<br/><br/>

            The presence of grey hairs, dandruff, split ends, and hair loss can indicate malnutrition and poor general health. A lack of vitamins and minerals may lead to pale, brittle nails with white spots and ridges that can exacerbate hair problems. The Hair IV was created to improve the condition of your hair and nails along with your overall health. A combination of B vitamins and minerals is included in this IV to support and strengthen the body. As opposed to most other treatments that aim to improve the superficial appearance of the hair and nails, Hair and Nails IV therapy works by treating the underlying problem and restoring natural health.<br/><br/>
        
            Anti hair loss IV is developed based on the hair production cycle of renewal and shedding. The therapy is composed of two infusion bags; the first contains calcium gluconate, iron, potassium chloride, magnesium gluconate, and B Complex and the second combines B12 and zinc.<br/><br/>
        
            Hormonal changes in the body affect Calcium levels, so menopausal women are often found to be suffering a deficiency in calcium. The second infusion bag contains B12 and zinc, which are required for DNA synthesis, the cell reproduction process occurring in the hair production cycle.`
        },
        {
            title: "IV DRIP",
            pageName: "Anti-aging IV Therapy",
            desc: `There are many aesthetic treatments available that can successfully treat lines and wrinkles, but most do not address the core problem of cellular damage caused by ageing. With glutathione – the mother of all antioxidants – our Anti-Ageing Booster IV helps fight the free radicals responsible for a number of ageing signs.<br/><br/>
            Additionally, this drip contains vital vitamin C and powerful B vitamins, essential for collagen production, which gives skin its elasticity. The B vitamins also support and repair our muscles when they are damaged, and also help to flush out lactic acid from the muscles. If you suffer muscular aches and pains regularly, you may be vitamin B deficient. Rich in these nutrients, Anti-Ageing Booster IV helps you to fight against free radicals that cause the signs of ageing.`
        },
        {
            title: "IV DRIP",
            pageName: "NAD",
            desc: `NAD also known as, Nicotinamide adenine dinucleotide, is a co-enzyme found in body cells, which is involved in the major metabolic functions of the cell. As we age, the production of this essential co-enzyme becomes less. The main functions of NAD are, to convert nutrients into energy and the regulation intracellular processes for long-term cellular health.
            The powerful anti-ageing properties of NAD treat age-related cell damage. The best way to absorb NAD is by intravenous injection. Our NAD + IV drip special formula is freshly prepared, and after consultation it will be given directly into your blood stream while being supervised by an experienced doctor and nurse. This will help you achieve a youthful feel and look by increasing the levels of NAD+ in your body. NAD also helps in repairing DNA damage from environmental stressors. Additionally, sirtuin genes, which are longevity genes, are activated, which decreases systemic inflammation.`
        },
        {
            title: "IV DRIP",
            pageName: "Jet Lag Booster",
            desc: `IV DRIP
            Jet Lag Booster
            Book Appointment
            When a body travels quickly through different time zones, its natural circadian rhythms are interrupted, resulting in jet lag or desynchronosis. Your 24-hour sleep-wake cycle, also referred to as your circadian rhythm or body clock, regulates your body’s physiological, biochemical, and behavioral processes. Although it is an internal mechanism for tracking time, we train it to control our actions from day to night by using outside forces.
    
            Jet lag is caused by upsetting and disrupting our internal clock, and its typical symptoms include anger, fatigue, lethargy, and the inability to focus. Additionally, you may encounter stomach issues, sleeplessness, loss of appetite, and dizziness. This only occurs when time zones are crossed, and it is typically more severe when traveling from the west.
            
            You’ve reached your location, but instead of rushing into meetings or venturing outside to view the sites, you’re completely exhausted after the flight. Your circadian rhythm can be quickly restored with the aid of an intravenous drip that administers vitamins and minerals directly into the bloodstream. They can also get rid of all of the effects of jet lag.
            
            Our Hydration IV Booster is a saline and electrolyte concoction that replenishes lost fluids, restores fluid balance, and flushes out lactic acid and toxins accumulated during our voyage. The Myers Cocktail, which combines vitamins C, B-12, B5, and B6, as well as crucial minerals like magnesium, selenium, and calcium, was the first and is still the most popular vitamin drip. These minerals can help reset your body clock because they are all tied to a poor regulation of it. Magnesium can also aid in muscle relaxation, which will hasten the process of falling asleep.`
        },
        {
            title: "IV DRIP",
            pageName: "Multivitamin Drip For Women",
            desc: `Even though we consume organic, healthy food and take supplements, we could constantly feel exhausted. We frequently discover that this is a result of digestive problems that hinder the appropriate absorption of nutrients. Given that up to 50% of the nutrients we take via food and oral supplements are never absorbed, it makes sense why so many of us constantly feel exhausted. With Multi-vitamin IV drips, infusions, and shots, the gut is not involved, resulting in 90–100% absorption and practically immediate benefits.<br/><br/>

            The best multivitamins for women comprise essential vitamins and minerals that have been shown to promote your body’s natural operation. Our multivitamin trip has combined Ashwagandha and Korean Ginseng for energy and cognition, ensuring that you never miss a beat. It also contributes to the regulation of hormonal activity.`
        },
        {
            title: "IV DRIP",
            pageName: "Post-surgery Recovery",
            desc: `If you recently had surgery, especially if it was an emergency procedure, and you notice that your recuperation is not going as well as you had planned, IV vitamin therapy will start supplying essential nutrients right away. To get the most benefit from this treatment, you should begin your IV vitamin therapy prior to surgery, even if it’s just by a few weeks. This will help to ensure that you are not missing out on essential nutrients that could help your recovery.<br/><br/>

    We provide a variety of IV Vitamin Therapy procedures at Finecare Clinic in Dubai that can increase vitamin and mineral levels. To get the best outcomes, we frequently combine therapies:<br/><br/>

    <strong>High-Dose Intravenous Vitamin C:</strong> is a vitamin powerhouse that plays a crucial part in collagen creation as well as acting as a highly effective agent to maintain a healthy immune system.<br/><br/>

    <strong>The Myers’ Cocktail:</strong> The potent combination of minerals and vitamins in the Myers’ Cocktail makes it a crucial component of any IV vitamin therapy treatment regimen, even though vitamin C can be prescribed as a very successful stand-alone treatment.<br/><br/>

    <strong>Glutathione IV:</strong> Glutathione is a very potent antioxidant that might lessen the oxidative stress brought on by the generation of free radicals throughout your procedure.`
        },
        {
            title: "IV DRIP",
            pageName: "Detox Drip",
            desc: `Increase your metabolism and get rid of the toxins that are stopping you from moving forward.<br/><br/>
            As we become more aware of the effects of toxins on the body’s capacity to function, detox has emerged as the next diet buzzword. The body will store toxins in your fat cells, in order to keep them away from your important organs, making them more difficult to remove.<br/><br/>
            In addition, our Detoxification IV drip contains a number of amino acids, such as glycine, taurine, arginine, and ornithine, which are crucial for the detoxification procedure and mix with and neutralize toxins in the liver. Our Detoxification IV drip is a potent blend of nutrients that can be a very helpful aid, but in order to get the results you want, you must also commit to a diet and exercise program.`
        },
        {
            title: "IV DRIP",
            pageName: "Athletic Choice",
            desc: `By supplying essential energy-boosting vitamins and amino acids, the Athletic Choice drip can increase your performance and prevent the comedown. Key B vitamins and amino acids like carnitine and taurine found in our Performance Booster  assist the body’s conversion of nutrients into energy.
            Additionally, magnesium and calcium, which are crucial for muscle function, are included in our Performance Booster IV. If you exercise regularly, you’ve certainly experienced hurting muscles and joints at some point. A lack of magnesium can cause muscle cramping and spasms. Magnesium can hasten your recuperation and aid with injury avoidance.        
            We’ve also added an electrolyte injection solution, which is necessary for adequate hydration. Electrolytes keep the body’s fluid balance in check, and any physical activity can quickly deplete them, leading to cramps, exhaustion, weakened muscles, and joint issues.`
        },
        {
            title: "IV DRIP",
            pageName: "Party Rescue IV Drip",
            desc: `Dehydration is the primary factor in hangovers. By refueling yourself with fluids beforehand, you can take whatever the night throws at you with ease. The additional B-complex will ensure that your body is properly equipped to manage anything you have planned for the evening.<br/><br/>

            By receiving an IV infusion of water and nutrients, you may stay hydrated, active, and lessen the unpleasant after-party hangover symptoms. Preparing for a big party, event, or after-party has been simpler with an IV drip hydration treatment. We’ll take care of your hydration, vitamins, and electrolytes need so you can stay joyful and energized throughout the night or the next day.`
        },
        {
            title: "IV DRIP",
            pageName: "Mood Enhancer Drip",
            desc: `
            Everyone experiences stress, but when it begins to have a severe impact on your health and well-being, it is critical to address it right away.
            
            Our IV drips can provide quick stress release. They can be carefully put together to maintain healthy cognitive function, rebalance your mind, and minimize exhaustion and fatigue.
            
            At Finecare Clinic, a high dose of glutamine, carnitine, and ornithine is added to our mood booster drip IV along with a mix of vitamins and minerals. These amino acids have been shown to give you the strength to deal with everyday life.`
        },
    ]

    const surgeries = [
        {
            title: "SURGERIES",
            pageName: "Liposuction",
            desc: `Liposuction, also known as fat removal surgery, is a cosmetic procedure designed to remove unwanted fat from specific areas of the body using a suction technique, resulting in a more defined and contoured shape. This procedure is suitable for both men and women and can target various parts of the body, including:
            <ul>
                <li>- Upper arms</li>
                <li>- Back</li>
                <li>- Inner and outer thighs</li>
                <li>- Flanks (love handles)</li>
                <li>- Hips</li>
                <li>- Face, neck, and chin</li>
                <li>- Stomach</li>
                <li>- Calves</li>
            </ul>
            Liposuction is effective in eliminating stubborn fat that typically resists diet and exercise efforts. It is widely regarded as the most effective technique for removing stubborn fat deposits in specific areas.<br/><br/>
            <strong>How long does the result of liposuction last?</strong><br/>
            The results of liposuction can be long-lasting. During the procedure, fat cells are permanently removed from the treated area, creating a more defined body shape. Since these fat cells do not regenerate, the results can be permanent, provided you maintain a healthy lifestyle that includes a balanced diet and regular exercise.`
        },
        {
            title: "SURGERIES",
            pageName: "Gastric Balloon & Smart Capsule",
            desc: `Liposuction, also known as fat removal surgery, is a cosmetic procedure designed to remove unwanted fat from specific areas of the body using a suction technique, resulting in a more defined and contoured shape. This procedure is suitable for both men and women and can target various parts of the body, including:
            <ul>
                <li>- Upper arms</li>
                <li>- Back</li>
                <li>- Inner and outer thighs</li>
                <li>- Flanks (love handles)</li>
                <li>- Hips</li>
                <li>- Face, neck, and chin</li>
                <li>- Stomach</li>
                <li>- Calves</li>
            </ul>
            Liposuction is effective in eliminating stubborn fat that typically resists diet and exercise efforts. It is widely regarded as the most effective technique for removing stubborn fat deposits in specific areas.<br/><br/>
            <strong>How long does the result of liposuction last?</strong><br/>
            The results of liposuction can be long-lasting. During the procedure, fat cells are permanently removed from the treated area, creating a more defined body shape. Since these fat cells do not regenerate, the results can be permanent, provided you maintain a healthy lifestyle that includes a balanced diet and regular exercise.`
        },
        {
            title: "SURGERIES",
            pageName: "Gynecomastia",
            desc: `Liposuction, also known as fat removal surgery, is a cosmetic procedure designed to remove unwanted fat from specific areas of the body using a suction technique, resulting in a more defined and contoured shape. This procedure is suitable for both men and women and can target various parts of the body, including:
            <ul>
                <li>- Upper arms</li>
                <li>- Back</li>
                <li>- Inner and outer thighs</li>
                <li>- Flanks (love handles)</li>
                <li>- Hips</li>
                <li>- Face, neck, and chin</li>
                <li>- Stomach</li>
                <li>- Calves</li>
            </ul>
            Liposuction is effective in eliminating stubborn fat that typically resists diet and exercise efforts. It is widely regarded as the most effective technique for removing stubborn fat deposits in specific areas.<br/><br/>
            <strong>How long does the result of liposuction last?</strong><br/>
            The results of liposuction can be long-lasting. During the procedure, fat cells are permanently removed from the treated area, creating a more defined body shape. Since these fat cells do not regenerate, the results can be permanent, provided you maintain a healthy lifestyle that includes a balanced diet and regular exercise.`
        },

    ]



    console.log(activeComponent)
    const [activeNavItem, setActiveNavItem] = useState(activeComponent ?? 'home');
    console.log(activeNavItem)
    const handleNavItemClick = (item, treatment) => {
        setActiveNavItem(item);
        onComponentChange(item, "", "", treatment);
        handleCloseMob()

    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showMob, setShowMob] = useState(false);
    const handleCloseMob = () => setShowMob(false);
    const handleShowMob = () => setShowMob(true);
    return (
        <div >
            <header className="header trans_400" >
                {/* <nav className="navbar" style={{ height: "35px", backgroundColor: "#AB7F21" }}>
                    <a className="navbar-brand" href="tel:+971 58 510 0850" style={{ marginBottom: "10px", color: "white", fontFamily: "Montserrat, sans-serif", fontSize: "15px" }}>
                        Call us now: +971 58 510 0850
                    </a>
                    
                    <div>
                        <a href="https://www.instagram.com/cosmohealthmc/" target="_blank" rel="noopener noreferrer" style={{ float: "right", marginBottom: "20px", marginRight: "10px" }} >
                            <FaInstagram size={20} color='white' />
                        </a>
                        <a href='https://www.facebook.com/CosmoHealthMedicalCenter/' target="_blank" rel="noopener noreferrer" style={{ float: "right", marginBottom: "20px", marginRight: "10px" }} >
                            <FaFacebook size={20} color='white' />
                        </a>
                        <a href="https://wa.me/971502008364" target="_blank" rel="noopener noreferrer" style={{ float: "right", marginBottom: "20px", marginRight: "10px" }} >
                            <FaWhatsapp size={20} color='white' />
                        </a>
                    </div>
                </nav> */}
                <div className="header_content d-flex flex-row align-items-center justify-content-start trans_400" >


                    {/* Logo */}
                    <div className="logo">
                        <Link to="/" onClick={() => handleNavItemClick('home')}>
                            <img src={logo} style={{ width: "80px" }} />
                        </Link>
                    </div>

                    {/* Navigation */}
                    <nav className="main_nav">
                        <ul className="d-flex flex-row align-items-center justify-content-start">
                            <li className={` ${activeNavItem === 'home' ? 'active' : ''}`}><Link to="/" onClick={() => handleNavItemClick('home')} style={{ fontSize: "14px" }}>Home</Link></li>
                            <li className={` ${activeNavItem === 'about' ? 'active' : ''}`}><Link to="/about" onClick={() => handleNavItemClick('about')} style={{ fontSize: "14px" }}>About Us</Link></li>
                            <li className={` ${activeNavItem === 'specialities' ? 'active' : ''}`}> <Link to="/specialities" onClick={() => handleNavItemClick('specialities')}>Specialities</Link></li>

                            {/* <li className={` ${activeNavItem === 'doctor' ? 'active' : ''}`}><Link to="/doctor" onClick={() => handleNavItemClick('doctor')} style={{ fontSize: "14px" }}>Doctors</Link></li> */}
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: "white", borderColor: "white", color: "black", fontWeight: "600", fontSize: "13px" }}>
                                    SURGERIES
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {surgeries.map((service, index) => (
                                        <Dropdown.Item key={index}>
                                            <Link style={{ color: "black" }} to={`/treatment/${service.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`} onClick={() => handleNavItemClick('treatment', service)}>
                                                {service.pageName}
                                            </Link>
                                        </Dropdown.Item>
                                    ))}

                                </Dropdown.Menu>
                            </Dropdown>
                            
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: "white", borderColor: "white", color: "black", fontWeight: "600", fontSize: "13px" }}>
                                    AESTHETIC
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {aesthetics.map((service, index) => (
                                        <Dropdown.Item key={index}>
                                            <Link style={{ color: "black" }} to={`/treatment/${service.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`} onClick={() => handleNavItemClick('treatment', service)}>
                                                {service.pageName}
                                            </Link>
                                        </Dropdown.Item>
                                    ))}

                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: "white", borderColor: "white", color: "black", fontWeight: "600", fontSize: "13px" }}>
                                    LASER TREATMENTS
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {LaserTreatment.map((service, index) => (
                                        <Dropdown.Item key={index}>
                                            <Link style={{ color: "black" }} to={`/treatment/${service.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`} onClick={() => handleNavItemClick('treatment', service)}>
                                                {service.pageName}
                                            </Link>
                                        </Dropdown.Item>
                                    ))}

                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: "white", borderColor: "white", color: "black", fontWeight: "600", fontSize: "13px" }}>
                                    WELLNESS
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {wellness.map((service, index) => (
                                        <Dropdown.Item key={index}>
                                            <Link style={{ color: "black" }} to={`/treatment/${service.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`} onClick={() => handleNavItemClick('treatment', service)}>
                                                {service.pageName}
                                            </Link>
                                        </Dropdown.Item>
                                    ))}

                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: "white", borderColor: "white", color: "black", fontWeight: "600", fontSize: "13px" }}>
                                    FACIAL TREATMENT
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {facialTreatment.map((service, index) => (
                                        <Dropdown.Item key={index}>
                                            <Link style={{ color: "black" }} to={`/treatment/${service.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`} onClick={() => handleNavItemClick('treatment', service)}>
                                                {service.pageName}
                                            </Link>
                                        </Dropdown.Item>
                                    ))}

                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: "white", borderColor: "white", color: "black", fontWeight: "600", fontSize: "13px" }}>
                                    DENTAL
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {dental.map((service, index) => (
                                        <Dropdown.Item key={index}>
                                            <Link style={{ color: "black" }} to={`/treatment/${service.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`} onClick={() => handleNavItemClick('treatment', service)}>
                                                {service.pageName}
                                            </Link>
                                        </Dropdown.Item>
                                    ))}

                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: "white", borderColor: "white", color: "black", fontWeight: "600", fontSize: "13px" }}>
                                    IV DRIP
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {ivDrip.map((service, index) => (
                                        <Dropdown.Item key={index}>
                                            <Link style={{ color: "black" }} to={`/treatment/${service.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`} onClick={() => handleNavItemClick('treatment', service)}>
                                                {service.pageName}
                                            </Link>
                                        </Dropdown.Item>
                                    ))}

                                </Dropdown.Menu>
                            </Dropdown>
                            <li className={` ${activeNavItem === 'contact' ? 'active' : ''}`}><Link to="/contact" onClick={() => handleNavItemClick('contact')} style={{ fontSize: "14px" }}>Contact</Link></li>

                           
                            <Button onClick={() => handleShow()} variant="primary" style={{ borderRadius: "25px", backgroundColor: "#ab7f21", borderColor: "#ab7f21" }}>Appointment</Button>{' '}

                        </ul>
                    </nav>

                    {/* Header Extra */}
                    <div className="header_extra d-flex flex-row align-items-center justify-content-end ml-auto">

                        {/* Work Hours */}
                        {/* <div className="work_hours">Mo - Sat: 8:00am - 9:00pm</div> */}

                        {/* Header Phone */}
                        {/* <div className="header_phone">+34 586 778 8892</div> */}

                        {/* Make an Appointment Button */}
                        {/* <div className="button button_1 header_button" style={{width: "120px", textAlign: "center"}}><a href="#" onClick={() => handleShow()}>Appointment</a></div> */}

                        {/* Social Icons */}


                        {/* Hamburger Menu Icon */}
                        <div className="hamburger" onClick={() => handleShowMob()}><FaAlignJustify color="black" size={30} /></div>
                    </div>
                </div>
            </header>
            <Appointment show={show} handleClose={handleClose} />

            <Modal show={showMob} onHide={handleCloseMob}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup >
                        <ListGroup.Item action style={{ border: "none", color: "black", fontWeight: "600", fontSize: "13px", marginLeft: "-8px" }}>
                            <Link to="/" onClick={() => handleNavItemClick('home')} style={{ color: "black" }}>Home</Link>
                        </ListGroup.Item>
                        <ListGroup.Item action style={{ border: "none", fontWeight: "600", fontSize: "13px", marginLeft: "-8px" }}>
                            <Link to="/about" onClick={() => handleNavItemClick('about')} style={{ color: "black" }}>About us</Link>                        </ListGroup.Item>
                        {/* <ListGroup.Item action style={{ border: "none", fontWeight: "600", fontSize: "13px", marginLeft: "-8px" }}>
                            <Link to="/doctor" onClick={() => handleNavItemClick('doctor')} style={{ color: "black" }}>Doctors</Link>
                        </ListGroup.Item> */}


                        {/* <ListGroup.Item action >
                            <Link to="/specialities" onClick={() => handleNavItemClick('specialities')}>Specialities</Link>
                        </ListGroup.Item> */}
                        {/* <ListGroup.Item action style={{ border: "none", fontWeight: "600", fontSize: "13px", marginLeft: "-8px" }}>
                            <Link to="/services" onClick={() => handleNavItemClick('services')} style={{ color: "black" }}>Services</Link>
                        </ListGroup.Item> */}
                        <ListGroup.Item action style={{ border: "none", fontWeight: "600", fontSize: "13px", marginLeft: "-8px" }}>
                            <Link to="/contact" onClick={() => handleNavItemClick('contact')} style={{ color: "black" }}>Contact</Link>
                        </ListGroup.Item>

                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: "white", borderColor: "white", color: "black", fontWeight: "600", fontSize: "13px" }}>
                                SURGERIES
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {surgeries.map((service, index) => (
                                    <Dropdown.Item key={index}>
                                        <Link style={{ color: "black" }} to={`/treatment/${service.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`} onClick={() => handleNavItemClick('treatment', service)}>
                                            {service.pageName}
                                        </Link>
                                    </Dropdown.Item>
                                ))}

                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: "white", borderColor: "white", color: "black", fontWeight: "600", fontSize: "13px" }}>
                                AESTHETIC
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {aesthetics.map((service, index) => (
                                    <Dropdown.Item key={index}>
                                        <Link style={{ color: "black" }} to={`/treatment/${service.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`} onClick={() => handleNavItemClick('treatment', service)}>
                                            {service.pageName}
                                        </Link>
                                    </Dropdown.Item>
                                ))}

                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: "white", borderColor: "white", color: "black", fontWeight: "600", fontSize: "13px" }}>
                                LASER TREATMENTS
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {LaserTreatment.map((service, index) => (
                                    <Dropdown.Item key={index}>
                                        <Link style={{ color: "black" }} to={`/treatment/${service.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`} onClick={() => handleNavItemClick('treatment', service)}>
                                            {service.pageName}
                                        </Link>
                                    </Dropdown.Item>
                                ))}

                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: "white", borderColor: "white", color: "black", fontWeight: "600", fontSize: "13px" }}>
                                WELLNESS
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {wellness.map((service, index) => (
                                    <Dropdown.Item key={index}>
                                        <Link style={{ color: "black" }} to={`/treatment/${service.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`} onClick={() => handleNavItemClick('treatment', service)}>
                                            {service.pageName}
                                        </Link>
                                    </Dropdown.Item>
                                ))}

                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: "white", borderColor: "white", color: "black", fontWeight: "600", fontSize: "13px" }}>
                                FACIAL TREATMENT
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {facialTreatment.map((service, index) => (
                                    <Dropdown.Item key={index}>
                                        <Link style={{ color: "black" }} to={`/treatment/${service.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`} onClick={() => handleNavItemClick('treatment', service)}>
                                            {service.pageName}
                                        </Link>
                                    </Dropdown.Item>
                                ))}

                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: "white", borderColor: "white", color: "black", fontWeight: "600", fontSize: "13px" }}>
                                DENTAL
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {dental.map((service, index) => (
                                    <Dropdown.Item key={index}>
                                        <Link style={{ color: "black" }} to={`/treatment/${service.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`} onClick={() => handleNavItemClick('treatment', service)}>
                                            {service.pageName}
                                        </Link>
                                    </Dropdown.Item>
                                ))}

                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: "white", borderColor: "white", color: "black", fontWeight: "600", fontSize: "13px" }}>
                                IV DRIP
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {ivDrip.map((service, index) => (
                                    <Dropdown.Item key={index}>
                                        <Link style={{ color: "black" }} to={`/treatment/${service.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`} onClick={() => handleNavItemClick('treatment', service)}>
                                            {service.pageName}
                                        </Link>
                                    </Dropdown.Item>
                                ))}

                            </Dropdown.Menu>
                        </Dropdown>
                        {/* <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: "white", borderColor: "white", color: "black", fontWeight: "600", fontSize: "13px" }}>
                                SURGERIES
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {surgeries.map((service, index) => (
                                    <Dropdown.Item key={index}>
                                        <Link style={{ color: "black" }} to={`/treatment/${service.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`} onClick={() => handleNavItemClick('treatment', service)}>
                                            {service.pageName}
                                        </Link>
                                    </Dropdown.Item>
                                ))}

                            </Dropdown.Menu>
                        </Dropdown> */}
                        
                    </ListGroup>


                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </div>
    )

}
export default Navbar