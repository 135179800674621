import Slider from "../Slider/Slider"
import Hero from "../Hero/Hero"
import SpecialitiesHome from "../SpecialitiesHome/SpecialitiesHome"
import ServicesHome from "../ServicesHome/ServicesHome"
import CardsHome from "../CardsHome/CardsHome"
import { Card } from "react-bootstrap"
import HomeDoctorHero from "../HomeDoctorHero/HomeDoctorHero"
import Gallery from "../Gallery/Gallery"
import CarouselSlider from "../CarouselSlider/CarouselSlider"
import Counter from "../Counter/Counter"
import ImageSlider from "../ImageSlider/ImageSlider"
import Row from 'react-bootstrap/Row';
import { Col } from "react-bootstrap";
import imageCarousel from "../imageCarousel/imageCarousel"
import { Helmet } from 'react-helmet';

function Home({ changeService }) {

    const imageObjects = [
        {
            id: 1,
            urlBefore: "beforeAfter/before1.jpg",
            urlAfter: "beforeAfter/after.jpeg"

        },
        // {
        //     id: 2,
        //     urlBefore: "beforeAfter/before.jpg",
        //     urlAfter: "beforeAfter/after.jpg"
        // }
    ];

    return (
        <div>
            <Helmet>
                <title>Cosmo Health Medical Center | The Best Aesthetic Clinic in Dubai

                </title>
                <meta name="description" content="Description: Explore the best cosmetic plastic surgery services in the UAE. From facelifts to breast augmentations, our expert surgeons deliver exceptional results." />
                {/* Add more meta tags if needed */}
            </Helmet>
            <CarouselSlider />
            
            <Counter />
            {/* <Slider /> */}

            <Hero />
            <CardsHome />
            
            {/* <HomeDoctorHero /> */}
            {/* <SpecialitiesHome /> */}
            <ServicesHome changeService={changeService} />
            {/* <div className="row" style={{ marginTop: "120px", marginBottom: "-90px" }}>
                <div className="col text-center">
                    <div className="section_title_container">
                        <div className="section_title"><h2 style={{ fontSize: '25px', marginTop: "-150px", color: "#ab7f21" }}>Before And After</h2></div>
                    </div>
                </div>
            </div> */}

            {/* <ImageSlider /> */}
            <div className="row" style={{ marginTop: "180px", marginBottom: "-90px" }}>
                <div className="col text-center">
                    <div className="section_title_container">
                        <div className="section_title"><h2 style={{ fontSize: '25px', marginTop: "-150px", color: "#ab7f21" }}>Gallery</h2></div>
                    </div>
                </div>
            </div>
            <Gallery />

        </div>
    )

}
export default Home