import Card from 'react-bootstrap/Card';
import './AboutHero.css';

function AboutHero() {
    return (
        <div className="intro" >
            <div className="container">
                <div className="row" >
                    {/* <div className="col-lg-3 ">
                        <div className="intro_image"><img src="Cosmo.png" alt="" /></div>
                    </div> */}
                    {/* Left Column */}
                    <div className="col-lg-12" style={{ marginTop: "150px" }}>
                        <div className="intro_content">
                            <div className="section_title_container">
                                {/* <div className="section_subtitle">This is Dr Pro</div> */}
                                <div className="section_title"><h2 style={{ fontSize: "25px" }}>About Us - Cosmo Health Medical Center</h2></div>
                            </div>
                            <div className="intro_text">
                                <p>Welcome to Cosmo Health Medical Center, your premier destination for comprehensive health and wellness services across the United Arab Emirates. With a robust presence in the region, including three branches in Dubai, and one each in Ras El Khaimah, Sharjah, and Ajman, we are dedicated to enhancing your health, beauty, and overall well-being through a wide array of specialized services. At Cosmo Health Medical Center, our mission is to provide exceptional care in a comfortable and welcoming environment, ensuring a seamless and enriching experience for all our patients.</p>
                            </div>
                        </div>
                    </div>



                </div>
                <br />
                <h4 style={{ color: "#ab7f21" }}>Our Expertise</h4>
                <p>
                    Cosmo Health Medical Center is proud to offer a diverse range of services tailored to meet your unique needs and goals. Our team of highly skilled professionals and state-of-the-art facilities enable us to deliver unparalleled care in the following areas:                            </p>

                <div className='row' style={{ marginBottom: "50px" }}>


                    <div className='col-lg-4'>
                        <Card className="hover-card" style={{ marginBottom: "30px", textAlign: "center" }}>
                            <Card.Img variant="top" src="/images/plastic surgery.jpg" />
                            <Card.Body>
                                <Card.Title style={{ color: "#AB7F21" }}>Plastic Surgery</Card.Title>
                                <Card.Text className="hover-text" style={{ color: "black", textAlign: "left" }}>
                                    Transform your appearance and boost your confidence with our advanced plastic surgery options. From body contouring to facial rejuvenation, our expert surgeons are committed to achieving your aesthetic aspirations with precision and care.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className='col-lg-4'>
                        <Card className="hover-card" style={{ marginBottom: "30px", textAlign: "center" }}>
                            <Card.Img variant="top" src="/images/dermatology.jpg" />
                            <Card.Body>
                                <Card.Title style={{ color: "#AB7F21" }}>Dermatology & Anti-Aging</Card.Title>
                                <Card.Text className="hover-text" style={{ color: "black", textAlign: "left" }}>
                                    Preserve your youthful glow and address skin concerns with our comprehensive dermatology and anti-aging solutions. Our specialists employ the latest technologies and treatments to promote healthy, radiant skin at any age.                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className='col-lg-4'>
                        <Card className="hover-card" style={{ marginBottom: "30px", textAlign: "center" }}>
                            <Card.Img variant="top" src="/images/hair treatment.jpg" />
                            <Card.Body>
                                <Card.Title style={{ color: "#AB7F21" }}>Hair Loss</Card.Title>
                                <Card.Text className="hover-text" style={{ color: "black", textAlign: "left" }}>
                                    Combat hair loss effectively with our tailored treatments and therapies. Our dedicated team offers innovative solutions to restore your hair's vitality and appearance, ensuring natural-looking and long-lasting results.                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className='col-lg-4'>
                        <Card className="hover-card" style={{ marginBottom: "30px", textAlign: "center" }}>
                            <Card.Img variant="top" src="/images/facials.jpg" style={{ height: "250px", objectFit: "cover" }} />
                            <Card.Body>
                                <Card.Title style={{ color: "#AB7F21" }}>Facials</Card.Title>
                                <Card.Text className="hover-text" style={{ color: "black", textAlign: "left" }}>
                                Indulge in the ultimate relaxation and rejuvenation experience at our Medical Spa. We provide a wide range of therapeutic and cosmetic services designed to enhance your beauty, relieve stress, and improve overall wellness.                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className='col-lg-4'>
                        <Card className="hover-card" style={{ marginBottom: "30px", textAlign: "center" }}>
                            <Card.Img variant="top" src="/images/weightloss.jpg" style={{ height: "250px", objectFit: "cover" }} />
                            <Card.Body>
                                <Card.Title style={{ color: "#AB7F21" }}>Weight Loss & Nutrition</Card.Title>
                                <Card.Text className="hover-text" style={{ color: "black", textAlign: "left" }}>
                                    Achieve your weight loss goals and nurture your body with our personalized nutrition plans and weight management programs. Our experts guide you through a holistic approach to healthy living, combining dietary adjustments, physical activity, and supportive therapies.                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className='col-lg-4'>
                        <Card className="hover-card" style={{ marginBottom: "30px", textAlign: "center" }}>
                            <Card.Img variant="top" src="/images/dental.jpg" style={{ height: "250px", objectFit: "cover" }} />
                            <Card.Body>
                                <Card.Title style={{ color: "#AB7F21" }}>Dentistry</Card.Title>
                                <Card.Text className="hover-text" style={{ color: "black", textAlign: "left" }}>
                                    Smile brighter with our comprehensive dental services. From routine check-ups to advanced cosmetic procedures, our dental professionals utilize the latest techniques to ensure optimal oral health and aesthetics.                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className='row no-gutters' style={{ padding: "10px", border: "2px solid #AB7F21" }}>
                        <div className="col-lg-6" style={{ width: "100%" }}>
                        <div className="intro_content" >
                        <div className="section_title_container">
                                    <div className="section_title">
                                        <h2 style={{ fontSize: "35px", color: "#AB7F21", marginTop: "155px" }}>Commitment to Excellence</h2>
                                        <br />

                                        {/* <h2 style={{fontSize: "25px", color: "white"}}>Discover Excellence in Healthcare Across the UAE</h2> */}
                                        <p style={{ fontSize: "16px", color: "black", textAlign: "left" }}>
                                            At Cosmo Health Medical Center, we are committed to delivering excellence in every aspect of our services. Our team continuously strives to innovate and improve, ensuring that we remain at the forefront of medical and aesthetic care. With a patient-centered approach, we focus on understanding and meeting your individual needs, providing personalized care that exceeds your expectations.
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" >
                            <img src="images/aboutUsHero1.jpg" style={{ width: "100%", objectFit: "cover" , height: "300px"}} />
                        </div>
                        
                    </div>

                    <div className='row no-gutters' style={{ padding: "10px", border: "2px solid #AB7F21", marginTop: "25px" }}>
                    <div className="col-lg-6" >
                            <img src="images/AboutUsHero2.jpg" style={{ width: "100%", objectFit: "cover", height: "300px" }} />
                        </div>
                        <div className="col-lg-6" style={{ width: "100%" }}>
                            <div className="intro_content" style={{ padding: "10px" }}>
                                <div className="section_title_container">
                                    <div className="section_title">
                                        <h2 style={{ fontSize: "35px", color: "#AB7F21", marginTop: "155px" }}>Join Our Community</h2>
                                        <br />

                                        {/* <h2 style={{fontSize: "25px", color: "white"}}>Discover Excellence in Healthcare Across the UAE</h2> */}
                                        <p style={{ fontSize: "16px", color: "black" }}>
                                        Whether you are seeking to enhance your appearance, address a specific health concern, or simply invest in your overall well-being, Cosmo Health Medical Center is here to support you on your journey. Visit us at any of our branches across Dubai, Ras El Khaimah, Sharjah, and Ajman, and discover the difference that compassionate, expert care can make in your life.</p>
                                        

                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                    {/* <h4 style={{ color: "#ab7f21" }}>Commitment to Excellence</h4>
                    <p>
                        At Cosmo Health Medical Center, we are committed to delivering excellence in every aspect of our services. Our team continuously strives to innovate and improve, ensuring that we remain at the forefront of medical and aesthetic care. With a patient-centered approach, we focus on understanding and meeting your individual needs, providing personalized care that exceeds your expectations.
                    </p>
                    <br />
                    <h4 style={{ color: "#ab7f21" }}>Join Our Community</h4>
                    <p>
                        Whether you are seeking to enhance your appearance, address a specific health concern, or simply invest in your overall well-being, Cosmo Health Medical Center is here to support you on your journey. Visit us at any of our branches across Dubai, Ras El Khaimah, Sharjah, and Ajman, and discover the difference that compassionate, expert care can make in your life.</p>
                    <br />
                    <p>Embrace a healthier, more vibrant you with Cosmo Health Medical Center – where excellence meets personalized care.</p>
 */}

                </div>
            </div>
        </div>
    )

}
export default AboutHero